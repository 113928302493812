import React from "react";

import AudioAnswer from "./AudioAnswer";
import VideoAnswer from "./VideoAnswer";

const Player = props => {
  const { selectedQuestion } = props;

  return (
    <>
      {selectedQuestion && selectedQuestion.answer ? (
        <>
          {selectedQuestion.answer.video_answer ? (
            <VideoAnswer
              video_answer={selectedQuestion.answer.video_answer}
              id={selectedQuestion.id}
            />
          ) : (
            <AudioAnswer audio={selectedQuestion.answer.audio_answer} />
          )}
        </>
      ) : null}
    </>
  );
};

export default Player;
