import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLifebook } from "../../../state/lifebook/actions";
import QuestionSet from "../components/QuestionSet";

const QuestionSetPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    lifebook: useSelector(state => state.lifebook.lifebook),
    categories: useSelector(state => state.question.categories),
    success: useSelector(state => state.lifebook.success),
    errors: useSelector(state => state.lifebook.errors)
  };

  const dispatchToProps = {
    getLifebook: useCallback(params => dispatch(getLifebook.request(params)), [
      dispatch
    ])
  };

  return <QuestionSet {...stateToProps} {...dispatchToProps}></QuestionSet>;
};

export default QuestionSetPage;
