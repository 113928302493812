import {
  CREATE_LIFEBOOK,
  CREATE_LIFEBOOK_LOCAL,
  ADD_QUESTION_LOCAL,
  CLEAR_LIFEBOOK_LOCAL
} from "./actions";

export const initialState = {
  lifebook: {
    first_name: "",
    last_name: "",
    gender: "",
    dob: "",
    email: ""
  },
  questions: [],
  errors: []
};

export const requesterReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_LIFEBOOK.SUCCESS: {
      return {
        ...state,
        lifebook: action.payload
      };
    }
    case CREATE_LIFEBOOK.ERROR: {
      return {
        ...state,
        errors: action.payload
      };
    }
    case CREATE_LIFEBOOK_LOCAL: {
      return {
        ...state,
        lifebook: action.payload
      };
    }
    case ADD_QUESTION_LOCAL: {
      let question = action.payload;
      return {
        ...state,
        questions: [...state.questions, question]
      };
    }
    case CLEAR_LIFEBOOK_LOCAL: {
      return {
        ...state,
        questions: [],
        errors: []
      };
    }
    default:
      return state;
  }
};
