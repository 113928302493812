import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Home from "../components/Home";
import { getLifebooks } from "../../../state/interviewee/actions";
import { getUser } from "../../../state/auth";
import { selectLifebook } from "../../../state/lifebook/actions";

const HomePage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    user: useSelector(state => state.auth.user),
    errors: useSelector(state => state.auth.errors),
    lifebooks: useSelector(state => state.interviewee.lifebooks),
    acceptedInvite: useSelector(state => state.auth.acceptedInvite)
  };

  const dispatchToProps = {
    getLifebooks: useCallback(user => dispatch(getLifebooks()), [dispatch]),
    getUser: useCallback(params => dispatch(getUser.request(params)), [
      dispatch
    ]),
    selectLifebook: useCallback(params => dispatch(selectLifebook(params)), [
      dispatch
    ])
  };

  return <Home {...stateToProps} {...dispatchToProps}></Home>;
};

export default HomePage;
