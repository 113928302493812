import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createQuestion } from "../../../state/lifebook/actions";

const AddQuestion = props => {
  const dispatch = useDispatch();
  const [question, setQuestion] = useState("");
  const lifebook = useSelector(state => state.lifebook.lifebook);
  const errors = useSelector(state => state.lifebook.errors);
  const success = useSelector(state => state.lifebook.success);

  const addQuestionToLifebook = e => {
    e.preventDefault();
    dispatch(
      createQuestion.request({
        meta: { method: "post", route: `lifebooks/${lifebook.uuid}/questions` },
        data: {
          prompt: question,
          lifebook: lifebook.id
        }
      })
    );
    setQuestion("");
  };

  return (
    <form className="form-section">
      <div className="success-errors-container">
        {success && <p className="paragraph center short-success">{success}</p>}
        {errors &&
          errors.map(error => <p className="paragraph short-error">{error}</p>)}
      </div>
      <div className="form-group">
        <input
          type="text"
          value={question}
          onChange={e => setQuestion(e.target.value)}
          placeholder={"Ask " + lifebook.user.first_name + " a new question"}
        />
        <button
          className="default-button teal-rounded"
          onClick={e => {
            addQuestionToLifebook(e);
          }}
        >
          Ask
        </button>
      </div>
    </form>
  );
};

export default AddQuestion;
