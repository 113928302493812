import { createActions, createActionTypes } from "../utils";

export const createLifebook = createActions("CREATE_LIFEBOOK");
export const CREATE_LIFEBOOK = createActionTypes("CREATE_LIFEBOOK");

export const sendIntervieweeInvitation = createActions(
  "SEND_INTERVIEWEE_INVITATION"
);
export const SEND_INTERVIEWEE_INVITATION = createActionTypes(
  "SEND_INTERVIEWEE_INVITATION"
);

export const addInitialQuestions = createActions("ADD_INITIAL_QUESTIONS");
export const ADD_INITIAL_QUESTIONS = createActionTypes("ADD_INITIAL_QUESTIONS");

export const addQuestion = createActions("ADD_QUESTION");
export const ADD_QUESTION = createActionTypes("ADD_QUESTION");

export const CREATE_LIFEBOOK_LOCAL = "CREATE_LIFEBOOK_LOCAL";
export const ADD_QUESTION_LOCAL = "ADD_QUESTION_LOCAL";
export const SET_LIFEBOOK_VALUE = "SET_LIFEBOOK_VALUE";
export const CLEAR_LIFEBOOK_LOCAL = "CLEAR_LIFEBOOK_LOCAL";

export const createLifebookLocal = params => {
  return {
    type: CREATE_LIFEBOOK_LOCAL,
    payload: params.data
  };
};

export const addQuestionLocal = params => {
  return {
    type: ADD_QUESTION_LOCAL,
    payload: params.data
  };
};

export const clearLifebookLocal = params => {
  return {
    type: CLEAR_LIFEBOOK_LOCAL
  };
};
