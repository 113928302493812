import React, { useState, useEffect } from "react";
import _ from "lodash";

const ErrorList = props => {
  const errors = props.errors || [];
  const header = props.header || "Please correct the following errors";

  const convertStartCase = msg => {
    if (msg.indexOf("non_field_errors:") > -1) {
      return msg.replace("non_field_errors:", "");
    }

    let colonIndex = msg.indexOf(":");
    if (colonIndex > 0) {
      const toStartCase = msg.slice(0, colonIndex);
      return msg.replace(toStartCase, _.startCase(toStartCase));
    }
    return msg;
  };

  return (
    <div className="error-section">
      {errors.length > 0 ? (
        <p className="paragraph short-error">{header}:</p>
      ) : null}
      <ul className="paragraph short-error">
        {errors.map((error, i) => (
          <li key={i}>{convertStartCase(error)}</li>
        ))}
      </ul>
    </div>
  );
};

export default ErrorList;
