import * as React from "react";
import axios from "axios";

export default function RequestPasswordReset() {
  const [email, setEmail] = React.useState("");
  const [isSent, setIsSent] = React.useState(false);

  const handleEmail = event => setEmail(event.target.value);

  const submitForm = event => {
    event.preventDefault();
    setIsSent(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}users/request_password_reset/`, {
        email
      })
      .catch(error => {});
  };

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">Reset password</h1>
          <p className="large-paragraph">
            Enter your email address and we'll send further instructions to your
            email!
          </p>
        </div>
        <form className="form-section" onSubmit={submitForm}>
          {isSent && (
            <div className="error-section">
              <p className="paragraph short-error">Email was sent!</p>
            </div>
          )}
          <div className="field-group">
            <label>Email</label>
            <input
              name="email"
              onChange={handleEmail}
              placeholder="Email"
              type="email"
              value={email}
            />
          </div>
          <div className="field-group">
            <button className="default-button full-width" type="submit">
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
