import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../../state/auth";
import SignUp from "../components/SignUp";

const SignUpPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    authErrors: useSelector(state => state.auth.errors),
    lifebookErrors: useSelector(state => state.requester.errors)
  };

  const dispatchToProps = {
    signUp: useCallback(params => dispatch(signUp.request(params)), [dispatch])
  };

  return <SignUp {...stateToProps} {...dispatchToProps}></SignUp>;
};

export default SignUpPage;
