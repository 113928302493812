export const getAirtableSetName = (id, categories) => {
  const category = categories.find(el => el.id === id);
  if (category) {
    return category.fields.Set;
  }
  return "Misc";
};

export const getAirtableSetOrder = (qId, catId, categories) => {
  const category = categories.find(el => el.id === catId);
  if (category) {
    return category.fields.Questions.indexOf(qId);
  }
  return -1;
};
