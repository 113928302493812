import * as React from "react";
import styled from "styled-components";

import PlaceholderVideo from "../../../assets/images/blurred-video.mp4";

const Container = styled.div`
  background: #f8e2cd;
  margin: 0 -25px;
  padding: 50px 0;
`;

const ListPage = styled.div`
  margin-top: 0;
  margin-bottom: 0;
`;

export default function Members(props) {
  return (
    <Container>
      <h3 className="heading-three center">
        Loved ones who have access to ask and view questions:
      </h3>
      <ListPage className="root-page-container list-page">
        <div className="opus-grid">
          {props.users.map(user => (
            <div className="opus-container">
              <div key={user.uuid} className="video-container">
                <video
                  src={PlaceholderVideo}
                  autoPlay
                  className="moving-placeholder"
                  loop
                  type="video/mp4"
                />
                <div className="name-container">
                  <h4 className="heading-four white">
                    {`${user.first_name} ${user.last_name}`}
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      </ListPage>
    </Container>
  );
}
