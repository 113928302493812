import * as React from "react";
import { ZiggeoRecorder } from "react-ziggeo";
import styled, { css } from "styled-components";
import { Link, useParams, useHistory } from "react-router-dom";

// import NextQuestion from "./NextQuestion";
import { getAirtableSetName, getAirtableSetOrder } from "../../helpers";

const Requester = styled.p`
  background: #ededed;
  color: #3e435e;
  margin: 0;
  padding: 20px 35px 20px 35px;
`;

const Layer = styled.div`
  ${props =>
    props.invisible &&
    css`
      display: none;
    `};
`;

const Success = styled.p`
  color: green;
`;

let UPLOADING = [];
let VERIFIED = [];

function useRecorder() {
  const [recorders, setRecorders] = React.useState([]);
  const [uploading, setUploading] = React.useState([]);
  const [verified, setVerifiedState] = React.useState([]);

  const addRecorder = uId => {
    if (!recorders.includes(uId)) setRecorders([...recorders, uId]);
  };
  const addUploading = uId => {
    if (!UPLOADING.includes(uId)) {
      UPLOADING.push(uId);
      setUploading(UPLOADING);
    }
  };
  const setVerified = uId => {
    VERIFIED.push(uId);
    UPLOADING = UPLOADING.filter(rId => rId !== uId);
    setUploading(UPLOADING);
    setVerifiedState(VERIFIED);
  };

  const hasVerified = verified.length > 0;
  const isUploading = uploading.length > 0;

  return {
    addRecorder,
    addUploading,
    hasVerified,
    isUploading,
    recorders,
    setVerified
  };
}

function ArrowIcon({ className }) {
  return (
    <svg
      baseProfile="basic"
      className={className}
      viewBox="0 0 90 112.5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M61.679 1.2l-8.62 14.931-8.62 14.931c-.923 1.599.231 3.599 2.078 3.599h10.865C54.397 59.052 33.57 78.01 8.384 78.01c-.985 0-1.784.799-1.784 1.784v8.423A1.785 1.785 0 008.384 90c31.804 0 58.03-24.321 61.068-55.341h11.545c1.847 0 3.001-1.999 2.078-3.599l-8.62-14.931L65.834 1.2c-.923-1.6-3.232-1.6-4.155 0z" />
    </svg>
  );
}

const ArrowIconStyled = styled(ArrowIcon)`
  height: 26px;
`;

const AnswerQuestion = props => {
  const { lifebook, errors, categories } = props;
  const { uuid } = useParams();
  const history = useHistory();
  // const [showNextQuestion, setShowNextQuestion] = React.useState(false);
  const [permissionsError, setPermissionsError] = React.useState(false);

  const {
    addRecorder,
    addUploading,
    hasVerified,
    isUploading,
    recorders,
    setVerified
  } = useRecorder();

  React.useEffect(() => {
    UPLOADING = [];
    VERIFIED = [];
  }, []);

  React.useEffect(() => {
    props.getMyLifebook();
    props.getCategories({
      meta: { method: "get", route: "Sets" }
    });
  }, [false]);

  React.useEffect(() => addRecorder(uuid), [uuid]);

  let unanswered =
    lifebook.questions &&
    lifebook.questions.filter(question => !question.answer);

  unanswered = unanswered.reduce((acc, curr) => {
    let airtable_set_name = getAirtableSetName(
      curr.airtable_set_id,
      categories
    );
    let airtable_set_order = getAirtableSetOrder(
      curr.airtable_id,
      curr.airtable_set_id,
      categories
    );
    return [...acc, { ...curr, airtable_set_name, airtable_set_order }];
  }, []);

  unanswered = unanswered.sort((a, b) => {
    if (a.airtable_set_id === b.airtable_set_id) {
      return a.airtable_set_order > b.airtable_set_order ? 1 : -1;
    }
    return a.airtable_set_name > b.airtable_set_name ? 1 : -1;
  });

  const question =
    (lifebook.questions && lifebook.questions.find(q => q.uuid === uuid)) ||
    lifebook.questions[0];

  const getNextQuestion = () => {
    const index = unanswered.findIndex(q => q.uuid === uuid);
    const nextIndex = index + 1;
    return nextIndex === unanswered.length ? null : unanswered[nextIndex];
  };

  const nextRoute = () => {
    const nextQuestion = getNextQuestion();

    const afterRedirect =
      nextQuestion === null
        ? `/lifebook/${lifebook.uuid}`
        : `/interviewee/answer-question/video/${nextQuestion.uuid}`;

    if (lifebook.user && !lifebook.user.has_password) {
      return `/interviewee/set-password?afterRedirect=${encodeURIComponent(
        afterRedirect
      )}`;
    } else if (lifebook && !lifebook.interviewee_video) {
      return `/interviewee/set-cover?afterRedirect=${encodeURIComponent(
        afterRedirect
      )}`;
    } else {
      return afterRedirect;
    }
  };

  const nextPrompt = () => {
    const nextQuestion = getNextQuestion();
    const lastQuestion = !nextQuestion;
    const text = lastQuestion
      ? "That was your last question. Thank you!"
      : nextQuestion && nextQuestion.prompt;
    return { text, lastQuestion };
  };

  nextPrompt();

  const goToNextRoute = () => {
    const nextQ = getNextQuestion();
    if (nextQ) history.push(`/interviewee/answer-question/video/${nextQ.uuid}`);
  };

  const handleUploading = uploadId => () => {
    addUploading(uploadId);
    goToNextRoute();
  };

  const handleVerified = uploadId => embedding => {
    setVerified(uploadId);

    const { video, duration } = embedding;
    const { embed_image_url, embed_video_url } = embedding.video_data;

    const meta = {
      method: "post",
      route: "videos",
      questionId: unanswered.find(q => q.uuid === uploadId).id
    };

    const nextQ = getNextQuestion();
    if (!nextQ) meta["afterRedirect"] = nextRoute();

    props.uploadVideo({
      meta,
      data: {
        token: video,
        duration,
        embed_image_url,
        embed_video_url
      }
    });
  };

  const handlePermissionsError = () => setPermissionsError(true);

  const handleUnload = event => {
    event.preventDefault();
    event.returnValue =
      "Videos are still uploading! Please wait uploads to finish to move on.";
  };

  const recorderProps = uploadId => ({
    key: uploadId,
    allowupload: false,
    apiKey: process.env.REACT_APP_VIDEO_UPLOAD_TOKEN,
    camerafacefront: true,
    flipCamera: true,
    gallerysnapshots: 0,
    height: "auto",
    mediaLocales: [
      {
        register: {
          "ba-videorecorder-controlbar.record": "Record"
        }
      }
    ],
    localplayback: true,
    "meta-profile": "_transcription",
    onAccessForbidden: handlePermissionsError,
    onNoCamera: handlePermissionsError,
    onNoMicrophone: handlePermissionsError,
    onUploading: handleUploading(uploadId),
    onVerified: handleVerified(uploadId),
    picksnapshotmandatory: true,
    picksnapshots: false,
    preventReRenderOnUpdate: true,
    recordingheight: 720,
    recordingwidth: 1280,
    skipinitial: true,
    skipinitialonrerecord: true,
    theme: "minimalist",
    "transcript-language": "en-US",
    webrtc_on_mobile: true,
    width: "stretch"
  });

  React.useEffect(() => {
    if (isUploading) {
      window.addEventListener("beforeunload", handleUnload);
      return () => window.removeEventListener("beforeunload", handleUnload);
    }
  }, [isUploading]);

  return (
    <div className="onboarding root-page-container vertical-align-layout answer-question-page">
      {permissionsError ? (
        <div className="permission-error-banner">
          <h4 className="heading-four">Permissions denied by browser!</h4>
          <p className="paragraph">
            Please ensure that your webcam and microphone are turned on and
            connected, and that your web browser has permissions to access them.
            If this problem persists, please contact us at{" "}
            <a href="mailto:help@hitreasure.com" className="default-link">
              help@hitreasure.com
            </a>
          </p>
        </div>
      ) : null}
      <div>
        {lifebook && lifebook.questions.length ? (
          <>
            {errors ? (
              <p className="large-paragraph center short-error">
                {errors && errors.map(error => <div>{error}</div>)}
              </p>
            ) : null}

            {isUploading ? (
              <p className="large-paragraph center short-error">
                Your Previous Answer Is Currently Saving
              </p>
            ) : hasVerified ? (
              <Success className="large-paragraph center short-error">
                Success!
              </Success>
            ) : null}

            <div className="onboarding__question-section video">
              <div className="card">
                <div className="first card-shared"></div>
                <div className="second card-shared"></div>

                <div className="question-information-container">
                  <div className="top-half">
                    <div className="watermark-container">
                      <svg
                        width="20"
                        height="29"
                        viewBox="0 0 20 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.72199 28.2129C6.72199 28.6271 7.09543 29 7.51037 29H12.4896C12.9046 29 13.278 28.6271 13.278 28.2129V6.09H19.2116C19.668 6.09 20 5.71714 20 5.30286V0.787142C20 0.372857 19.668 0 19.2116 0H0.788381C0.33195 0 0 0.372857 0 0.787142V5.30286C0 5.71714 0.33195 6.09 0.788381 6.09H6.72199V28.2129Z"
                          fill="#EDEDED"
                        />
                      </svg>
                    </div>
                    <div className="question-container">
                      <h2 className="heading-two no-margin">
                        {question.prompt}
                      </h2>
                    </div>
                  </div>
                </div>

                <Requester className="heading-four">
                  {lifebook.initiated_by_user.first_name} wants to know{" "}
                  <ArrowIconStyled />
                </Requester>

                <div className="recording-container video">
                  <>
                    {/* {showNextQuestion ? (
                      <div className="question-preview-container">
                        <NextQuestion prompt={nextPrompt()} />
                      </div>
                    ) : null} */}
                    {recorders.map(uploadId => (
                      <Layer key={uploadId} invisible={uploadId !== uuid}>
                        <ZiggeoRecorder {...recorderProps(uploadId)} />
                      </Layer>
                    ))}
                  </>
                </div>
              </div>
            </div>

            <div className="skip-section center">
              <span
                className="default-link large-paragraph"
                onClick={goToNextRoute}
              >
                Skip this Question
              </span>

              <Link
                className="default-link gray large-paragraph"
                to={`/lifebook/${lifebook.uuid}`}
              >
                Return Later
              </Link>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AnswerQuestion;
