import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createLifebookLocal,
  clearLifebookLocal,
  createLifebook
} from "../../../state/requester/actions";

import CreateLifebook from "../components/CreateLifebook";

const CreateLifebookPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    signedIn: useSelector(state => state.auth.signedIn),
    lifebook: useSelector(state => state.requester.lifebook),
    errors: useSelector(state => state.requester.errors)
  };

  const dispatchToProps = {
    createLifebookLocal: useCallback(
      params => dispatch(createLifebookLocal(params)),
      [dispatch]
    ),
    clearLifebookLocal: useCallback(
      params => dispatch(clearLifebookLocal(params)),
      [dispatch]
    ),
    createLifebook: useCallback(
      params => dispatch(createLifebook.request(params)),
      [dispatch]
    )
  };

  return (
    <CreateLifebook {...stateToProps} {...dispatchToProps}></CreateLifebook>
  );
};

export default CreateLifebookPage;
