import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { ZiggeoRecorder } from "react-ziggeo";

import { getUrlParameter } from "../../../state/utils";

import LivingPortrait from "../../../assets/mp4/LivingPortrait.mp4";

const Container = styled.div`
  height: 100%;
  max-width: 100%;
  width: 100%;
`;

const Video = styled.video`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const SetCover = props => {
  const afterRedirect = getUrlParameter("afterRedirect");
  const { errors } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    props.getMyLifebook();
    dispatch({ type: "CLEAR_MESSAGES" });
  }, [false]);

  const handleRecorderUploaded = embedding => {
    const token = embedding.video;
    const { embed_image_url, embed_video_url } = embedding.video_data;

    props.uploadVideo({
      data: {
        token,
        duration: embedding.duration,
        embed_image_url,
        embed_video_url
      },
      meta: {
        method: "post",
        route: "videos",
        is_interviewee_video: true
      }
    });
  };

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">Record your Living Portrait</h1>
          <p className="large-paragraph">
            A “Living Portrait” is your Treasure profile picture. We’ve included
            some examples to show you how they are used. Once you’re ready,
            simply record a fun 8 second video. Remember, it will only record
            picture, no sound.
          </p>
        </div>
        {errors ? (
          <p className="paragraph center short-error">
            {errors && errors.map(error => <div>{error}</div>)}
          </p>
        ) : null}

        <Grid spacing={4} container>
          <Grid xs={12} md={6} item>
            <Container className="onboarding__video-section">
              <Video src={LivingPortrait} autoPlay loop muted />
            </Container>
          </Grid>
          <Grid xs={12} md={6} item>
            <Container className="onboarding__video-section">
              <ZiggeoRecorder
                apiKey={process.env.REACT_APP_VIDEO_UPLOAD_TOKEN}
                onVerified={handleRecorderUploaded}
                theme="minimalist"
                timelimit={6}
                width="stretch"
                height="auto"
                mediaLocales={[
                  {
                    register: {
                      "ba-videorecorder-controlbar.record": "Record"
                    }
                  }
                ]}
                meta-profile="_transcription"
                transcript-language="en-US"
                recordingwidth="1280"
                recordingheight="720"
                camerafacefront
                flipCamera
                localplayback
                picksnapshotmandatory
                skipinitial
                skipinitialonrerecord
                webrtc_on_mobile
              />
              <section className="tips-section">
                <h4 className="heading-four">Some tips on what to say</h4>
                <ul className="list paragraph">
                  <li>Make sure you are centered in the frame</li>
                  <li>
                    Show some personality (Ideas: laugh, smile, dance or wave)
                  </li>
                  <li>
                    Remember there is no sound, so don’t worry about saying
                    anything
                  </li>
                </ul>
              </section>
            </Container>
          </Grid>
        </Grid>

        <div className="onboarding__button-section">
          <Link to={afterRedirect}>
            <button
              disabled={!props.lifebookUpdatedWithVideo}
              className="default-button"
            >
              Next
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SetCover;
