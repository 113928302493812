import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { Link, useParams } from "react-router-dom";
import { Link as AnimatedLink } from "react-scroll";

import AddSet from "./AddSet";
import Player from "./Player";
import Loading from "./Loading";
import Members from "./Members";
import CoverVideo from "./CoverVideo";
import AddQuestion from "./AddQuestion";
import QuestionList from "./QuestionList";
import AnswerQuestionSection from "./AnswerQuestionSection";

import PlaceholderVideo from "../../../assets/images/blurred-video.mp4";

const Section = styled.div`
  margin-top: 0 !important;

  ${props =>
    props.transparent &&
    css`
      background: transparent !important;
    `};
`;

const Lifebook = props => {
  const params = useParams();
  const { uuid } = params;
  const { lifebook, user, answeredQuestions } = props;
  const dispatch = useDispatch();

  const selectedQuestion =
    "question" in params
      ? answeredQuestions.find(question => question.uuid === params.question)
      : false;

  useEffect(() => {
    dispatch({ type: "CLEAR_MESSAGES" });
  }, [false]);

  useEffect(() => {
    props.getLifebook({
      meta: {
        method: "get",
        route: `lifebooks/${uuid}`
      }
    });
    props.getUser({ data: {}, meta: {} });
  }, [props.acceptedInvite]);

  useEffect(() => {
    return () => dispatch({ type: "SELECT_QUESTION", payload: null });
  }, [false]);

  return (
    <div className="root-page-container detail-page">
      <Loading />
      <div>
        {lifebook && lifebook.user && user ? (
          <>
            <div className="title-container">
              <span className="link-container">
                <Link to={`/home`} className="default-link">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.70679 0.292786C8.51926 0.105315 8.26495 0 7.99979 0C7.73462 0 7.48031 0.105315 7.29279 0.292786L2.29279 5.29279C2.10532 5.48031 2 5.73462 2 5.99979C2 6.26495 2.10532 6.51926 2.29279 6.70679L7.29279 11.7068C7.48139 11.8889 7.73399 11.9897 7.99619 11.9875C8.25838 11.9852 8.5092 11.88 8.6946 11.6946C8.88001 11.5092 8.98518 11.2584 8.98746 10.9962C8.98974 10.734 8.88894 10.4814 8.70679 10.2928L4.41379 5.99979L8.70679 1.70679C8.89426 1.51926 8.99957 1.26495 8.99957 0.999786C8.99957 0.734622 8.89426 0.480314 8.70679 0.292786Z"
                      fill="#3E435E"
                    />
                  </svg>
                  Family Home
                </Link>
              </span>
              <h1 className="heading-one no-margin center">
                {user.id === lifebook.user.id
                  ? "Your Life"
                  : lifebook.user.first_name +
                    " " +
                    lifebook.user.last_name +
                    "'s Life"}
              </h1>
            </div>

            {user.id === lifebook.user.id && lifebook.questions.length ? (
              <div className="answer-questions-banner">
                <AnimatedLink
                  to="answer-questions"
                  smooth={true}
                  offset={-100}
                  duration={500}
                >
                  <svg
                    width="19"
                    height="19"
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 0C4.275 0 0 4.275 0 9.5C0 14.725 4.275 19 9.5 19C14.725 19 19 14.725 19 9.5C19 4.275 14.725 0 9.5 0ZM9.5 14.25C8.7875 14.25 8.3125 13.775 8.3125 13.0625C8.3125 12.35 8.7875 11.875 9.5 11.875C10.2125 11.875 10.6875 12.35 10.6875 13.0625C10.6875 13.775 10.2125 14.25 9.5 14.25ZM10.6875 10.6875H8.3125V4.75H10.6875V10.6875Z"
                      fill="#fff"
                    />
                  </svg>
                  View Unanswered Questions
                </AnimatedLink>
              </div>
            ) : null}

            <section className="video-question-playlist-section">
              <div className="video-question-container">
                <div className="video-box">
                  <div className="video-player">
                    {selectedQuestion ? (
                      <Player
                        lifebook={lifebook}
                        selectedQuestion={selectedQuestion}
                      />
                    ) : lifebook.interviewee_video ? (
                      <CoverVideo
                        interviewee_video={lifebook.interviewee_video}
                      />
                    ) : (
                      <>
                        <div className="placeholder-overlay"></div>
                        <video
                          src={PlaceholderVideo}
                          autoPlay
                          className="moving-placeholder"
                          loop
                          type="video/mp4"
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="question-box">
                  <AddQuestion />
                </div>
              </div>
              <div className="playlist-container">
                <QuestionList lifebook={lifebook} />
              </div>
            </section>

            <div className="add-set-section">
              <h2 className="heading-two center">
                Ask{" "}
                {user.id === lifebook.user.id
                  ? "Yourself"
                  : lifebook.user.first_name}{" "}
                More Questions
              </h2>
              <AddSet />
              <div className="more-button-row center">
                <Link to={`/lifebook/${uuid}/questions`}>
                  <button className="default-button">More Questions</button>
                </Link>
              </div>
            </div>

            <Members users={lifebook.users} />

            <Section className="add-family-section" transparent>
              <h3 className="heading-three center">
                Your Treasure Is Only Complete When Your Whole Family Is Here.
                Invite them to ask{" "}
                {user.id === lifebook.user.id
                  ? "you"
                  : lifebook.user.first_name}{" "}
                questions and see their answers.
              </h3>
              <Link className="center" to={`/send-invite/${uuid}`}>
                <button className="default-button no-margin">
                  Add family members
                </button>
              </Link>
            </Section>

            <Section className="add-family-section">
              <h3 className="heading-three center">
                Invite a loved one to be a SUBJECT of their own Treasure
              </h3>
              <Link className="center" to="/">
                <button className="default-button no-margin">
                  Make Them Feel Special
                </button>
              </Link>
            </Section>

            {user.id === lifebook.user.id ? (
              <AnswerQuestionSection lifebook={lifebook} />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Lifebook;
