import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const SignOut = props => {
  const history = useHistory();
  const signoutUser = () => {
    props.signOut();
    history.push("/signin");
  };

  return (
    <button
      onClick={() => {
        signoutUser();
      }}
    >
      Sign Out
    </button>
  );
};

export default SignOut;
