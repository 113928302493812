import { VideoActionTypes } from "./types";
import history from "../history";

const {
  UPLOAD_VIDEO,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_ERROR
} = VideoActionTypes;

export const uploadVideo = ({ meta, data }) => {
  return {
    type: UPLOAD_VIDEO,
    meta,
    payload: {
      ziggeo_video_token: data.token,
      ziggeo_metadata: {
        image_url: data.embed_image_url,
        video_url: data.embed_video_url,
        duration: data.duration
      }
    }
  };
};

export const uploadVideoSuccess = res => {
  return { type: UPLOAD_VIDEO_SUCCESS, payload: res.data };
};

export const uploadVideoError = error => {
  return { type: UPLOAD_VIDEO_ERROR, payload: error };
};
