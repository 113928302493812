import React, { useState, useEffect } from "react";
import { ZiggeoPlayer } from "react-ziggeo";
import { useDispatch } from "react-redux";
import { playNextMedia } from "../../../state/lifebook/actions";

const VideoAnswer = props => {
  const { video_answer, id } = props;
  const [showRecorder, setShowRecorder] = useState(true);
  const [videoToken, setVideoToken] = useState(video_answer.ziggeo_video_token);
  const [recorder, setRecorder] = useState(null);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (recorder) {
  //   }
  // }, [recorder]);

  useEffect(() => {
    setShowRecorder(false);
    setTimeout(() => {
      setShowRecorder(true);
    }, 1);
    setVideoToken(video_answer.ziggeo_video_token);
  }, [video_answer.ziggeo_video_token]);

  return (
    <>
      {showRecorder ? (
        <ZiggeoPlayer
          preventReRenderOnUpdate={false}
          apiKey={process.env.REACT_APP_VIDEO_UPLOAD_TOKEN}
          video={videoToken}
          theme={"minimalist"}
          skipinitial={true}
          autoplay={true}
          width={"stretch"}
          volume={1}
          onEnded={() => {
            dispatch(playNextMedia(id));
          }}
          onRef={ref => setRecorder(ref)}
          webrtc_on_mobile
          webrtc_streaming
        />
      ) : (
        <p>There is no recorder</p>
      )}
    </>
  );
};

export default VideoAnswer;
