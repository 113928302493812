import axios from "axios";

export default function lifebooksApi(method, path) {
  const token = process.env.REACT_APP_AIRTABLE_API_TOKEN;

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`
  };

  return axios({
    method,
    headers,
    url:
      process.env.REACT_APP_AIRTABLE_API_URL +
      process.env.REACT_APP_AIRTABLE_BASE_ID +
      "/" +
      path
  });
}
