import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Lifebook from "../components/Lifebook";

import { getLifebook, selectQuestion } from "../../../state/lifebook/actions";
import { getUser } from "../../../state/auth";

const LifebookPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    user: useSelector(state => state.auth.user),
    acceptedInvite: useSelector(state => state.auth.acceptedInvite),
    lifebook: useSelector(state => state.lifebook.lifebook),
    selectedQuestion: useSelector(state => state.lifebook.selectedQuestion),
    answeredQuestions: useSelector(state => state.lifebook.answeredQuestions)
  };

  const dispatchToProps = {
    getLifebook: useCallback(params => dispatch(getLifebook.request(params)), [
      dispatch
    ]),
    getUser: useCallback(params => dispatch(getUser.request(params)), [
      dispatch
    ]),
    selectQuestion: useCallback(params => dispatch(selectQuestion(params)), [
      dispatch
    ])
  };

  return <Lifebook {...stateToProps} {...dispatchToProps}></Lifebook>;
};

export default LifebookPage;
