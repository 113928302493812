import { useState, useEffect } from "react";

const NICKNAME_KEY = "nickname";

export default function useNickname() {
  const [nickname, setNickname] = useState("");

  useEffect(() => {
    const saved = sessionStorage.getItem(NICKNAME_KEY);
    if (saved) setNickname(saved);
  });

  const onClear = () => {
    setNickname("");
    sessionStorage.removeItem(NICKNAME_KEY);
  };

  const onSave = (value: string) => {
    setNickname(value);
    sessionStorage.setItem(NICKNAME_KEY, value);
  };

  return {
    nickname,
    onClear,
    onSave
  };
}
