import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createQuestion, getLifebook } from "../../../state/lifebook/actions";
import { getCategories, getQuestions } from "../../../state/question";
import { useParams, Link } from "react-router-dom";
import Loading from "./Loading";

const QuestionSet = props => {
  const dispatch = useDispatch();
  let { lifebook, success, errors, categories } = props;
  const { uuid } = useParams();

  useEffect(() => {
    dispatch(
      getCategories.request({
        meta: { method: "get", route: "Sets" }
      })
    );
    props.getLifebook({
      meta: {
        method: "get",
        route: `lifebooks/${uuid}`
      }
    });
  }, [false]);

  const addSetToLifebook = obj => {
    const filterQuery = `FIND("${obj.fields &&
      obj.fields.Set}", {Categories Question is IN})`;
    const encodedQuery = encodeURIComponent(filterQuery);
    dispatch(
      getQuestions.request({
        meta: {
          method: "get",
          route: "Questions",
          createQuestionSet: true,
          afterAction: {
            meta: {
              method: "post",
              route: `lifebooks/${lifebook.uuid}/questions`
            },
            data: {
              lifebook: lifebook.id,
              airtable_set_id: obj.id
            }
          }
        },
        data: {
          queryString: `filterByFormula=${encodedQuery}`
        }
      })
    );
  };

  const blacklist =
    lifebook &&
    lifebook.questions &&
    lifebook.questions.reduce((acc, question) => {
      if (acc.indexOf(question.airtable_set_id) === -1) {
        acc.push(question.airtable_set_id);
      }
      return acc;
    }, []);

  return (
    <div className="root-page-container add-question-page">
      <Loading />
      <div className="title-container">
        <span className="link-container">
          <Link to={`/lifebook/${uuid}`} className="default-link">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.70679 0.292786C8.51926 0.105315 8.26495 0 7.99979 0C7.73462 0 7.48031 0.105315 7.29279 0.292786L2.29279 5.29279C2.10532 5.48031 2 5.73462 2 5.99979C2 6.26495 2.10532 6.51926 2.29279 6.70679L7.29279 11.7068C7.48139 11.8889 7.73399 11.9897 7.99619 11.9875C8.25838 11.9852 8.5092 11.88 8.6946 11.6946C8.88001 11.5092 8.98518 11.2584 8.98746 10.9962C8.98974 10.734 8.88894 10.4814 8.70679 10.2928L4.41379 5.99979L8.70679 1.70679C8.89426 1.51926 8.99957 1.26495 8.99957 0.999786C8.99957 0.734622 8.89426 0.480314 8.70679 0.292786Z"
                fill="#3E435E"
              />
            </svg>
            Back
          </Link>
        </span>
        <h1 className="heading-one no-margin center">Add more questions</h1>
      </div>
      {success ? (
        <p className="paragraph center short-success">{success}</p>
      ) : null}
      {errors ? (
        <p className="paragraph center short-error">
          {errors && errors.map(error => <div>{error}</div>)}
        </p>
      ) : null}
      {categories && categories.length > 0 ? (
        <div>
          <div className="questions-page__question-section">
            {categories.map((category, key) => {
              let added;
              if (blacklist && blacklist.indexOf(category.id) > -1) {
                added = true;
              }
              return (
                <div
                  className={added ? "card added" : "card"}
                  key={key}
                  onClick={() => {
                    if (!added) {
                      addSetToLifebook(category);
                    }
                  }}
                >
                  <div className="add-question">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M50 100C22.4302 100 0 77.5709 0 50C0 22.4291 22.4302 0 50 0C77.5698 0 100 22.4291 100 50C100 77.5709 77.5698 100 50 100Z"
                        fill="#00A3A9"
                      />
                      <path
                        d="M71.7391 47.826H52.1739V28.2608C52.1739 27.0592 51.2005 26.0869 50 26.0869C48.7994 26.0869 47.826 27.0592 47.826 28.2608V47.826H28.2608C27.0603 47.826 26.0869 48.7984 26.0869 50C26.0869 51.2015 27.0603 52.1739 28.2608 52.1739H47.826V71.7391C47.826 72.9407 48.7994 73.913 50 73.913C51.2005 73.913 52.1739 72.9407 52.1739 71.7391V52.1739H71.7391C72.9396 52.1739 73.913 51.2015 73.913 50C73.913 48.7984 72.9396 47.826 71.7391 47.826Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  {added ? (
                    <div className="success">
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M50 100C22.43 100 0 77.57 0 50C0 22.43 22.43 0 50 0C77.57 0 100 22.43 100 50C100 77.57 77.57 100 50 100Z"
                          fill="#72C472"
                        />
                        <path
                          d="M40.9765 65.6894L27.6432 52.3561C26.3416 51.0545 26.3416 48.9441 27.6432 47.6425C28.9448 46.3409 31.0551 46.3409 32.3567 47.6425L43.3333 58.6191L67.6432 34.3092C68.9448 33.0076 71.0551 33.0076 72.3567 34.3092C73.6583 35.6108 73.6583 37.7211 72.3567 39.0227L45.6901 65.6894C44.3885 66.991 42.2781 66.991 40.9765 65.6894Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  ) : null}
                  <div className="first card-shared"></div>
                  <div className="second card-shared"></div>
                  <div className="top-half">
                    <div className="watermark-container">
                      <svg
                        width="20"
                        height="29"
                        viewBox="0 0 20 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.72199 28.2129C6.72199 28.6271 7.09543 29 7.51037 29H12.4896C12.9046 29 13.278 28.6271 13.278 28.2129V6.09H19.2116C19.668 6.09 20 5.71714 20 5.30286V0.787142C20 0.372857 19.668 0 19.2116 0H0.788381C0.33195 0 0 0.372857 0 0.787142V5.30286C0 5.71714 0.33195 6.09 0.788381 6.09H6.72199V28.2129Z"
                          fill="#EDEDED"
                        />
                      </svg>
                    </div>
                    <div className="question-container">
                      <h3 className="question-label">
                        {category.fields && category.fields.Set}
                      </h3>
                    </div>
                  </div>
                  <div className="count-container">
                    <h4 className="count-label">
                      1 /{" "}
                      {(category.fields &&
                        category.fields.Questions &&
                        category.fields.Questions.length) ||
                        0}{" "}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default QuestionSet;
