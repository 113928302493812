import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendInvite, getUser } from "../../../state/auth";
import SendInvite from "../components/SendInvite";
import { getLifebook } from "../../../state/lifebook/actions";

const SendInvitePage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    user: useSelector(state => state.auth.user),
    errors: useSelector(state => state.auth.errors),
    success: useSelector(state => state.auth.success),
    lifebook: useSelector(state => state.lifebook.lifebook)
  };

  const dispatchToProps = {
    sendInvite: useCallback(params => dispatch(sendInvite.request(params)), [
      dispatch
    ]),
    getLifebook: useCallback(params => dispatch(getLifebook.request(params)), [
      dispatch
    ]),
    getUser: useCallback(params => dispatch(getUser.request(params)), [
      dispatch
    ])
  };

  return <SendInvite {...stateToProps} {...dispatchToProps}></SendInvite>;
};

export default SendInvitePage;
