import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAnswer,
  getMyLifebook
} from "../../../state/interviewee/actions";
import { updateUser } from "../../../state/auth";
import { pollTwilio } from "../../../state/audio";
import AnswerQuestionPhone from "../components/AnswerQuestionPhone";
import { getCategories } from "../../../state/question/";

const AnswerQuestionPhonePage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    lifebook: useSelector(state => state.interviewee.lifebook),
    categories: useSelector(state => state.question.categories),
    errors: useSelector(state => [...state.audio.errors, state.auth.errors]),
    callStarted: useSelector(state => state.audio.callStarted),
    callCompleted: useSelector(state => state.audio.callCompleted),
    callStatus: useSelector(state => state.audio.callStatus),
    call: useSelector(state => state.audio.call)
  };

  const dispatchToProps = {
    getMyLifebook: useCallback(() => dispatch(getMyLifebook()), [dispatch]),
    updateUser: useCallback(params => dispatch(updateUser.request(params)), [
      dispatch
    ]),
    pollTwilio: useCallback(params => dispatch(pollTwilio.request(params)), [
      dispatch
    ]),
    deleteAnswer: useCallback(
      params => dispatch(deleteAnswer.request(params)),
      [dispatch]
    ),
    getCategories: useCallback(
      params => dispatch(getCategories.request(params)),
      [dispatch]
    )
  };

  return (
    <AnswerQuestionPhone
      {...stateToProps}
      {...dispatchToProps}
    ></AnswerQuestionPhone>
  );
};

export default AnswerQuestionPhonePage;
