import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLifebooks,
  getMyLifebook
} from "../../../state/interviewee/actions";
import { getCategories } from "../../../state/question";
import Landing from "../components/Landing";

const LandingPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    //user: useSelector(state => state.auth.user),
    //errors: useSelector(state => state.auth.errors)
    lifebook: useSelector(state => state.interviewee.lifebook),
    categories: useSelector(state => state.question.categories)
  };

  const dispatchToProps = {
    getMyLifebook: useCallback(params => dispatch(getMyLifebook(params)), [
      dispatch
    ]),
    getLifebooks: useCallback(user => dispatch(getLifebooks()), [dispatch]),
    getCategories: useCallback(
      params =>
        dispatch(
          getCategories.request({
            meta: { method: "get", route: "Sets" }
          })
        ),
      [dispatch]
    )
  };

  return <Landing {...stateToProps} {...dispatchToProps}></Landing>;
};

export default LandingPage;
