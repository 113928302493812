import React, { useState, useEffect } from "react";
import Select from "react-select";

import { getUrlParameter } from "../../../state/utils";
import ErrorList from "../../shared/components/ErrorList";

const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" }
];

const customSelectStyles = {
  control: (base, state) => ({
    ...base,
    borderRadius: "19px",
    borderWidth: state.isFocused ? "2px" : "1px",
    boxSizing: "border-box",
    borderColor: state.isFocused ? "#3e435e" : "#f8e2cd",
    boxShadow: "0px 5px 13px rgba(244, 161, 144, 0.12)",
    fontSize: "1.19rem",
    fontFamily: "metropolismedium",
    color: "#2b2d34",
    paddingLeft: state.isFocused ? "11px" : "12px",
    margin: "0",
    "&:hover": {
      paddingLeft: "11px",
      borderWidth: "2px",
      borderColor: "#3e435e"
    }
  }),
  valueContainer: base => ({
    ...base,
    padding: "0",
    boxSizing: "border-box"
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#3e435e",
    padding: state.isFocused ? "7px 11px" : "8px 12px"
  }),
  indicatorSeparator: base => ({
    ...base,
    display: "none"
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? "#f8e2cd" : "#fff",
    color: "#2b2d34"
  }),
  menu: base => ({
    ...base,
    borderRadius: "19px",
    hyphens: "auto",
    fontSize: "1.19rem",
    marginTop: 0,
    textAlign: "left",
    wordWrap: "break-word"
  }),
  menuList: base => ({
    ...base,
    paddingTop: "14px",
    paddingBottom: "14px"
  })
};

const SetPassword = props => {
  const [gender, setGender] = useState(null);
  const [form, setForm] = React.useState({
    password: "",
    gender: "",
    dob: ""
  });

  useEffect(() => {
    props.getUser({ data: {}, meta: {} });
  }, [false]);

  const handleInput = e => {
    const { name, value } = e.currentTarget;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSelect = obj => {
    setGender(obj);
    setForm({ ...form, gender: obj.value });
  };

  const submitForm = e => {
    e.preventDefault();
    const afterRedirect = getUrlParameter("afterRedirect");
    const userId = props.user.id;

    props.setPassword({
      data: form,
      meta: {
        route: `me/${userId}`,
        method: "patch",
        afterRedirect: `/interviewee/set-cover?afterRedirect=${encodeURIComponent(
          afterRedirect
        )}`
      }
    });
  };

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">
            Great start. They're going to love it!
          </h1>
          <p className="large-paragraph">
            Now, create a password, so you and your family can treasure this
            answer forever.
          </p>
        </div>

        <form className="form-section" onSubmit={submitForm}>
          <ErrorList errors={props.errors} />
          <div className="field-sets">
            <div className="first field-group">
              <label>Gender</label>
              <Select
                name="gender"
                value={gender}
                onChange={handleSelect}
                options={genderOptions}
                styles={customSelectStyles}
              />
            </div>
            <div className="second field-group">
              <label>Date of Birth</label>
              <input
                name="dob"
                onChange={handleInput}
                placeholder="Password"
                type="date"
                value={form.dob}
              />
            </div>
          </div>
          <div className="field-group">
            <label>Password</label>
            <input
              name="password"
              onChange={handleInput}
              placeholder="Password"
              type="password"
              value={form.password}
            />
          </div>
          <div className="field-group">
            <button className="default-button full-width" type="submit">
              Save Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
