import { createActions, createActionTypes } from "../utils";

export const startTwilio = createActions("START_TWILIO");
export const START_TWILIO = createActionTypes("START_TWILIO");

export const pollTwilio = createActions("POLL_TWILIO");
export const POLL_TWILIO = createActionTypes("POLL_TWILIO");

export const startPolling = () => {
  return {
    type: "START_POLLING"
  };
};

export const START_POLLING = "START_POLLING";

export const stopPolling = () => {
  return {
    type: "STOP_POLLING"
  };
};

export const STOP_POLLING = "STOP_POLLING";

export const CLEAR_MESSAGES = "CLEAR_MESSAGES";

export const initialState = {
  call: {},
  callStarted: false,
  callCompleted: false,
  callStatus: null,
  errors: []
};

export const audioReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_TWILIO.SUCCESS:
      return {
        ...state,
        callStarted: true,
        call: action.payload,
        callCompleted: false,
        errors: []
      };
    case START_TWILIO.ERROR:
      return {
        ...state,
        errors: action.payload
      };
    case POLL_TWILIO.SUCCESS:
      return {
        ...state,
        callCompleted: action.payload.audio_answer.status !== "unknown",
        callStatus: action.payload.audio_answer.status,
        errors: []
      };
    case POLL_TWILIO.ERROR:
      return {
        ...state
        //errors: action.payload
      };
    case CLEAR_MESSAGES:
      return {
        ...state,
        callStarted: false,
        callCompleted: false,
        callStatus: null,
        errors: []
      };
    default:
      return state;
  }
};
