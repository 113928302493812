import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadVideo } from "../../../state/video/actions";
import { getMyLifebook } from "../../../state/interviewee/actions";
import SetCover from "../components/SetCover";

const SetCoverPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    user: useSelector(state => state.auth.user),
    lifebookUpdatedWithVideo: useSelector(
      state => state.interviewee.lifebookUpdatedWithVideo
    ),
    success: useSelector(state => state.interviewee.success),
    errors: [
      ...useSelector(state => state.interviewee.errors),
      useSelector(state => state.video.errors)
    ]
  };

  const dispatchToProps = {
    uploadVideo: useCallback(params => dispatch(uploadVideo(params)), [
      dispatch
    ]),
    getMyLifebook: useCallback(params => dispatch(getMyLifebook(params)), [
      dispatch
    ])
  };

  return <SetCover {...stateToProps} {...dispatchToProps}></SetCover>;
};

export default SetCoverPage;
