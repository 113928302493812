import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import { all, fork } from "redux-saga/effects";
import sagaMiddleware from "./middleware/sagas";
import authSaga from "./auth/sagas";
import requesterSaga from "./requester/sagas";
import videoSaga from "./video/sagas";
import intervieweeSaga from "./interviewee/sagas";
import audioSaga from "./audio/sagas";
import lifebookSaga from "./lifebook/sagas";

import { authReducer } from "./auth";
import { requesterReducer } from "./requester/reducers";
import { videoReducer } from "./video/reducers";
import { intervieweeReducer } from "./interviewee/reducers";
import { audioReducer } from "./audio";
import { lifebookReducer } from "./lifebook/reducers";

import questionReducer from "./question";
import questionSaga from "./question/sagas";

const reducer = combineReducers({
  auth: authReducer,
  requester: requesterReducer,
  video: videoReducer,
  interviewee: intervieweeReducer,
  question: questionReducer,
  audio: audioReducer,
  lifebook: lifebookReducer
});

export function* saga() {
  yield all([
    fork(authSaga),
    fork(requesterSaga),
    fork(lifebookSaga),
    fork(intervieweeSaga),
    fork(videoSaga),
    fork(questionSaga),
    fork(audioSaga)
  ]);
}

export default function configureStore(initialState) {
  const composeEnhancers =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

  const store = createStore(reducer, enhancer);
  sagaMiddleware.run(saga);
  return store;
}
