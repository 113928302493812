import React, { useState, useEffect } from "react";
import { ZiggeoPlayer } from "react-ziggeo";

const CoverVideo = ({ interviewee_video }) => {
  const [volume, setVolume] = useState(0);
  useEffect(() => {
    return setVolume(1);
  });
  return (
    <>
      {interviewee_video && interviewee_video.ziggeo_video_token ? (
        <div style={{ zIndex: -1 }}>
          <ZiggeoPlayer
            apiKey={process.env.REACT_APP_VIDEO_UPLOAD_TOKEN}
            video={interviewee_video.ziggeo_video_token}
            theme={"space"}
            skipinitial={true}
            autoplay={true}
            loop={true}
            playonclick={false}
            volume={volume}
            // picksnapshots={false}
            // gallerysnapshots={0}
            hidebarafter={-1}
            width={"stretch"}
            height={"auto"}
            mediaLocales={[
              {
                register: {
                  "ba-videorecorder-controlbar.record": "Record Portrait"
                }
              }
            ]}
            webrtc_on_mobile
            webrtc_streaming
          />
        </div>
      ) : null}
    </>
  );
};

export default CoverVideo;
