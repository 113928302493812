import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CoverVideo from "./CoverVideo";
import PlaceholderVideo from "../../../assets/images/blurred-video.mp4";
import ReactGA from "react-ga";

const Home = props => {
  const { user, lifebooks } = props;

  useEffect(() => {
    props.getLifebooks();
    props.getUser({ data: {}, meta: {} });
  }, [props.acceptedInvite]);

  useEffect(() => {
    if (user) {
      ReactGA.set({ userId: user.id });
    }
  }, [user]);

  const index =
    lifebooks && user && lifebooks.findIndex(el => el.user.id === user.id);

  if (index && index > 0) {
    let tmp = lifebooks[0];
    lifebooks[0] = lifebooks[index];
    lifebooks[index] = tmp;
  }

  const selectLifebook = lifebook => {
    props.selectLifebook(lifebook);
  };

  return (
    <div className="root-page-container list-page">
      <div>
        <div className="title">
          <h1 className="heading-one center">Your Family Home</h1>
          <p className="large-paragraph center">
            This is where your family lives
          </p>
        </div>
        <div className="opus-grid">
          {lifebooks &&
            user &&
            lifebooks.reduce((acc, lifebook, index) => {
              const { uuid, interviewee_video } = lifebook;
              return [
                ...acc,
                <>
                  <Link
                    onClick={() => {
                      selectLifebook(lifebook);
                    }}
                    className="opus-container"
                    to={`/lifebook/${uuid}`}
                    key={index}
                  >
                    <div className="video-container">
                      {interviewee_video ? (
                        <CoverVideo interviewee_video={interviewee_video} />
                      ) : (
                        <video
                          src={PlaceholderVideo}
                          autoPlay
                          className="moving-placeholder"
                          loop
                          type="video/mp4"
                        />
                        // <div className="static-placeholder"></div>
                      )}
                      <div className="name-container">
                        <h4 className="heading-four white">
                          {user.id === lifebook.user.id
                            ? "Your Life Story"
                            : lifebook.user.first_name +
                              " " +
                              lifebook.user.last_name}
                        </h4>
                      </div>
                    </div>
                  </Link>
                </>
              ];
            }, [])}
          <Link className="opus-container" to={`/`}>
            <div className="add-opus-container">
              <video
                src={PlaceholderVideo}
                autoPlay
                className="moving-placeholder"
                loop
                type="video/mp4"
              />
              <div className="name-container">
                <h4 className="heading-four white">Create a Treasure</h4>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
