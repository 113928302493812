import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { capitalize } from "../../../state/utils";
import useNickname from "../../../hooks/nickname";

const SendInvite = props => {
  const { lifebook, user, success, errors } = props;
  const dispatch = useDispatch();

  const [form, setForm] = React.useState({});
  const { uuid } = useParams();

  const handleInputChange = e => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  React.useEffect(() => {
    props.getLifebook({
      meta: {
        method: "get",
        route: `lifebooks/${uuid}`
      }
    });
    props.getUser({ data: {}, meta: {} });
    dispatch({ type: "CLEAR_MESSAGES" });
  }, [uuid]);

  const { onClear: clearNickname } = useNickname();
  React.useEffect(() => {
    clearNickname();
  }, []);

  const submitForm = e => {
    e.preventDefault();
    props.sendInvite({
      meta: {
        route: `lifebooks/${uuid}/invite_family_member`,
        method: "post"
      },
      data: form
    });
    setForm({ email: "", first_name: "", last_name: "" });
  };

  const displayName = React.useMemo(() => {
    if (user && lifebook && lifebook.user) {
      if (user.id === lifebook.user.id) return "You";
      return capitalize(lifebook.user.first_name) || "User";
    }
    return "User";
  }, [lifebook, user]);

  return (
    <div className="onboarding root-page-container vertical-align-layout add-family-member">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">Invite Your Family</h1>
          <p className="large-paragraph">
            So they can ask {displayName} questions and hear their answers
          </p>
        </div>

        <form className="form-section" onSubmit={submitForm}>
          <div className="error-section">
            {success ? (
              <p className="paragraph center short-success">{success}</p>
            ) : null}
            {errors ? (
              <ul className="paragraph short-error">
                {errors && errors.map(error => <li>{error}</li>)}
              </ul>
            ) : null}
          </div>
          <div className="field-group">
            <label>First Name</label>
            <input
              type="text"
              name="first_name"
              value={form["first_name"]}
              placeholder="First Name"
              onChange={handleInputChange}
            />
          </div>
          <div className="field-group">
            <label>Last Name</label>
            <input
              type="text"
              name="last_name"
              value={form["last_name"]}
              placeholder="Last Name"
              onChange={handleInputChange}
            />
          </div>
          <div className="field-group  no-margin">
            <label>Email</label>
            <input
              type="text"
              name="email"
              value={form["email"]}
              placeholder="Email"
              onChange={handleInputChange}
            />
          </div>

          <div className="field-group button-section">
            <button className="default-button full-width" type="submit">
              Send Invite
            </button>{" "}
            <Link to={`/lifebook/${uuid}/questions`}>
              <button className="ghost-button">
                Ask {displayName} more questions
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SendInvite;
