import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ZiggeoRecorder } from "react-ziggeo";

import useNickname from "../../../hooks/nickname";
import { getUrlParameter } from "../../../state/utils";

const Tips = styled.div`
  background: transparent;
`;

const Highlight = styled.span`
  background: #3e435e;
  border-radius: ${props => (props.small ? 7 : 19)}px;
  color: #fdf6ef;
  padding: 0 0.2em;
`;

const AddVideo = props => {
  const uuid = getUrlParameter("lifebookUuid");

  useEffect(() => {
    props.getLifebook({ data: { uuid } });
  }, [false]);

  const handleRecorderVerified = embedding => {
    const token = embedding.video;
    const { embed_image_url, embed_video_url } = embedding.video_data;

    props.uploadVideo({
      data: {
        token,
        duration: embedding.duration,
        embed_image_url,
        embed_video_url
      },
      meta: {
        method: "post",
        route: "videos",
        is_intro_video: true
      }
    });
  };

  const { nickname } = useNickname();

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">
            Welcome <Highlight>{nickname}</Highlight> with a friendly face and a
            short video welcome message!
          </h1>
          <p className="large-paragraph">
            When you’re finished click next below.
          </p>
        </div>
        <div className="onboarding__video-section">
          <ZiggeoRecorder
            apiKey={process.env.REACT_APP_VIDEO_UPLOAD_TOKEN}
            onVerified={handleRecorderVerified}
            theme="minimalist"
            allowupload={false}
            width="stretch"
            height="auto"
            meta-profile="_transcription"
            transcript-language="en-US"
            recordingwidth="1280"
            recordingheight="720"
            mediaLocales={[
              {
                register: {
                  "ba-videorecorder-controlbar.record": "Record"
                }
              }
            ]}
            camerafacefront
            flipCamera
            localplayback
            picksnapshotmandatory
            skipinitial
            skipinitialonrerecord
            webrtc_on_mobile
          />
          <Tips className="expanding-tips-section">
            <a href="#" className="click-zone heading-four">
              Some tips on what to say
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.70271 5.08396C9.31321 5.46865 8.68679 5.46865 8.2973 5.08396L3.85255 0.694039C3.46306 0.309346 2.83664 0.309346 2.44714 0.694039L0.720366 2.39952C0.323973 2.79102 0.323973 3.43098 0.720365 3.82248L8.2973 11.306C8.68679 11.6907 9.31321 11.6907 9.70271 11.306L17.2796 3.82248C17.676 3.43098 17.676 2.79102 17.2796 2.39952L15.5529 0.694039C15.1634 0.309347 14.5369 0.309347 14.1474 0.694039L9.70271 5.08396Z" />
              </svg>
            </a>
            <ul className="list paragraph">
              <li>
                Don’t over think it, it’s about how you make them feel, not what
                you say!
              </li>
              <li>Tell them how important they are!</li>
              <li>
                Tell them you can’t wait to hear more about their life story!
              </li>
            </ul>
          </Tips>
        </div>
        <div className="onboarding__button-section">
          <Link to={`/finish-lifebook/${uuid}`}>
            <button
              disabled={!props.lifebookUpdatedWithVideo}
              className="default-button"
            >
              Next
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AddVideo;
