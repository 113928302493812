import { VideoActionTypes } from "./types";

const {
  UPLOAD_VIDEO,
  UPLOAD_VIDEO_SUCCESS,
  UPLOAD_VIDEO_ERROR
} = VideoActionTypes;

export const initialState = {
  video: {},
  errors: []
};

export const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_VIDEO: {
      return {
        ...state,
        loading: true
      };
    }
    case UPLOAD_VIDEO_SUCCESS: {
      return {
        ...state,
        loading: false,
        video: action.payload,
        videoUploaded: true
      };
    }
    case UPLOAD_VIDEO_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }
    default:
      return state;
  }
};
