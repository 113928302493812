import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  getLifebook,
  GET_LIFEBOOK,
  getLifebooks,
  GET_LIFEBOOKS,
  createQuestion,
  CREATE_QUESTION
} from "./actions";
import lifebooksApi from "../middleware/lifebooksApi";
import { handleErrors } from "../utils";

function* handleGetLifebook(action) {
  try {
    const res = yield call(lifebooksApi, action.meta.method, action.meta.route);
    yield put(getLifebook.success(res.data));
  } catch (err) {
    yield put(getLifebook.error(handleErrors(err)));
  }
}

function* handleGetLifebooks(action) {
  try {
    const res = yield call(lifebooksApi, action.meta.method, action.meta.route);
    yield put(getLifebooks.success(res.data));
  } catch (err) {
    yield put(getLifebooks.error(handleErrors(err)));
  }
}

function* handleCreateQuestion(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload
    );
    yield put(createQuestion.success(res.data));
  } catch (err) {
    yield put(createQuestion.error(handleErrors(err)));
  }
}

export default function* lifebookSaga() {
  yield all([
    takeLatest(GET_LIFEBOOK.REQUEST, handleGetLifebook),
    takeLatest(GET_LIFEBOOKS.REQUEST, handleGetLifebooks),
    takeLatest(CREATE_QUESTION.REQUEST, handleCreateQuestion)
  ]);
}
