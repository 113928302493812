export const createActionTypes = base => {
  return {
    REQUEST: `${base}_REQUEST`,
    SUCCESS: `${base}_SUCCESS`,
    ERROR: `${base}_ERROR`
  };
};

export const createActions = base => {
  return {
    request: ({ data, meta }) => {
      return { type: `${base}_REQUEST`, meta, payload: data };
    },
    success: res => {
      return { type: `${base}_SUCCESS`, payload: res };
    },
    error: err => {
      return { type: `${base}_ERROR`, payload: err };
    }
  };
};

export const noAuthHeader = [
  { method: "post", path: "users" },
  { method: "post", path: "api-token-auth" }
];

export const handleErrors = err => {
  if (err instanceof Error && err.response) {
    if (err.response.status === 404) {
      return ["Requested resource not found. Please try again"];
    }
    if (err.response.status === 500) {
      return ["Internal server error."];
    }

    const clientErrorCodes = [400, 401, 409];
    if (
      typeof err.response.data === "object" &&
      clientErrorCodes.includes(err.response.status)
    ) {
      return processErrors(err.response.data);
    }
  }
  return ["An unknown error occurred. Please try again."];
};

export const processErrors = obj => {
  let res = [];
  for (let k in obj) {
    res.push(`${k}: ${obj[k]}`);
  }
  return res;
};

export const getUrlParameter = name => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const setCache = (name, item) => {
  item = JSON.stringify(item);
  localStorage.setItem(name, item);
};

export const validateEmail = email => {
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const capitalize = str => {
  if (typeof str !== "string") {
    return null;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};
