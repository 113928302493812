import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateUser, getUser } from "../../../state/auth";
import { getLifebook } from "../../../state/interviewee/actions";
import SetPassword from "../components/SetPassword";

const SetPasswordPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    user: useSelector(state => state.auth.user),
    errors: useSelector(state => state.auth.errors)
  };

  const dispatchToProps = {
    setPassword: useCallback(params => dispatch(updateUser.request(params)), [
      dispatch
    ]),
    getUser: useCallback(params => dispatch(getUser.request(params)), [
      dispatch
    ]),
    getLifebook: useCallback(() => dispatch(getLifebook()), [dispatch])
  };

  return <SetPassword {...stateToProps} {...dispatchToProps}></SetPassword>;
};

export default SetPasswordPage;
