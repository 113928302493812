import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import OnboardingSelectCategory from "../components/OnboardingSelectCategory";
import {
  getCategories,
  getQuestions,
  selectCategory
} from "../../../state/question";
import {
  addQuestionLocal,
  addInitialQuestions
} from "../../../state/requester/actions";

const OnboardingSelectCategoryPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    categories: useSelector(state => state.question.categories),
    questions: useSelector(state => state.question.questions),
    errors: useSelector(state => state.auth.errors),
    signedIn: useSelector(state => state.auth.signedIn),
    lifebook: useSelector(state => state.requester.lifebook)
  };

  const dispatchToProps = {
    getCategories: useCallback(
      params => dispatch(getCategories.request(params)),
      [dispatch]
    ),
    selectCategory: useCallback(data => dispatch(selectCategory(data)), [
      dispatch
    ]),
    getQuestions: useCallback(
      params => dispatch(getQuestions.request(params)),
      [dispatch]
    ),
    addQuestionToLifebook: useCallback(
      params => dispatch(addQuestionLocal(params)),
      [dispatch]
    ),
    addInitialQuestions: useCallback(
      params => dispatch(addInitialQuestions.request(params)),
      [dispatch]
    )
  };

  return (
    <OnboardingSelectCategory
      {...stateToProps}
      {...dispatchToProps}
    ></OnboardingSelectCategory>
  );
};

export default OnboardingSelectCategoryPage;
