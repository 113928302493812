import axios from "axios";
import { noAuthHeader } from "../utils";

export default async function lifebooksApi(method, path, data) {
  const token = localStorage.getItem("token");

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Token ${token}`
  };

  noAuthHeader.forEach(route => {
    if (
      route.method.toLowerCase() === method.toLowerCase() &&
      route.path.toLowerCase() === path.toLowerCase()
    ) {
      delete headers["Authorization"];
    }
  });

  return axios({
    method,
    headers,
    url: process.env.REACT_APP_API_URL + path + "/",
    data: data ? JSON.stringify(data) : null
  });
}
