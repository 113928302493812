import React from "react";
import { Link } from "react-router-dom";
import history from "../../../state/history";
import { useDispatch, useSelector } from "react-redux";

const Navigation = props => {
  const dispatch = useDispatch();
  const signedIn = useSelector(state => state.auth.signedIn);

  const signoutUser = () => {
    dispatch({ type: "SIGN_OUT" });
    const target = signedIn ? "/" : "/signin";
    history.push(target);
  };

  return (
    <div className="navigation">
      <ul>
        <li>
          <Link to="/home">
            <svg
              width="190"
              height="24"
              viewBox="0 0 190 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.022 22.4112C5.022 22.7212 5.301 23.0002 5.611 23.0002H9.331C9.641 23.0002 9.92 22.7212 9.92 22.4112V5.85723H14.353C14.694 5.85723 14.942 5.57823 14.942 5.26823V1.88923C14.942 1.57923 14.694 1.30023 14.353 1.30023H0.589C0.248 1.30023 0 1.57923 0 1.88923V5.26823C0 5.57823 0.248 5.85723 0.589 5.85723H5.022V22.4112Z"
                fill="#F4A190"
              />
              <path
                d="M23.4597 22.4112C23.4597 22.7212 23.7077 23.0002 24.0487 23.0002H27.7377C28.0477 23.0002 28.3267 22.7212 28.3267 22.4112V14.5062H30.4657L34.5887 22.7212C34.6507 22.8452 34.8367 23.0002 35.0847 23.0002H39.2077C39.7347 23.0002 39.9517 22.5042 39.7347 22.1012L35.4877 14.2272C38.1847 13.1422 40.0757 10.8482 40.0757 7.99623C40.0757 4.30723 37.0377 1.30023 33.3177 1.30023H24.0487C23.7077 1.30023 23.4597 1.57923 23.4597 1.88923V22.4112ZM28.3267 10.5692V5.73323H32.9147C34.1857 5.73323 35.2707 6.78724 35.2707 8.08923C35.2707 9.39124 34.1857 10.5692 32.9147 10.5692H28.3267Z"
                fill="#F4A190"
              />
              <path
                d="M49.6523 22.4112C49.6523 22.7212 49.9003 23.0002 50.2413 23.0002H63.0753C63.4163 23.0002 63.6643 22.7212 63.6643 22.4112V19.0322C63.6643 18.7222 63.4163 18.4432 63.0753 18.4432H54.4883V14.2582H61.5563C61.8663 14.2582 62.1453 14.0102 62.1453 13.6692V10.2902C62.1453 9.98023 61.8663 9.70123 61.5563 9.70123H54.4883V5.85723H63.0753C63.4163 5.85723 63.6643 5.57823 63.6643 5.26823V1.88923C63.6643 1.57923 63.4163 1.30023 63.0753 1.30023H50.2413C49.9003 1.30023 49.6523 1.57923 49.6523 1.88923V22.4112Z"
                fill="#F4A190"
              />
              <path
                d="M70.9784 23.0002H74.3884C75.0084 23.0002 75.3494 22.6282 75.5044 22.2562L76.6204 19.7762H85.0524L86.1684 22.2252C86.4474 22.8142 86.6954 23.0002 87.2534 23.0002H90.6944C91.1594 23.0002 91.4074 22.5972 91.2214 22.1942L81.5184 1.33123C81.4254 1.14523 81.2084 0.990234 80.9914 0.990234H80.6814C80.4644 0.990234 80.2474 1.14523 80.1544 1.33123L70.4514 22.1942C70.2654 22.5972 70.5134 23.0002 70.9784 23.0002ZM78.4804 15.5602L80.8054 10.4452H80.8364L83.1924 15.5602H78.4804Z"
                fill="#F4A190"
              />
              <path
                d="M98.1068 20.9232C98.8198 21.6362 101.052 23.3102 105.082 23.3102C109.639 23.3102 112.398 20.0862 112.398 16.8932C112.398 12.6772 108.554 10.7862 106.136 9.82524C103.687 8.83324 102.757 7.96523 102.757 6.81823C102.757 5.98123 103.594 5.23724 104.71 5.23724C106.601 5.23724 108.864 6.94224 109.143 7.09724C109.422 7.31423 109.98 7.03523 110.197 6.69423L111.778 4.30723C111.902 4.09024 111.964 3.53224 111.592 3.31523C110.662 2.63324 108.182 0.990234 104.958 0.990234C100.184 0.990234 97.7968 4.09023 97.7968 7.15923C97.7968 10.8792 101.145 12.9562 103.811 14.0102C105.919 14.8472 107.004 15.8392 107.004 17.1412C107.004 18.2262 106.105 19.0012 104.865 19.0012C102.819 19.0012 100.587 17.3582 100.463 17.2962C100.246 17.1412 99.6568 17.0792 99.4088 17.4822L97.9518 20.0552C97.7038 20.4892 97.7968 20.6132 98.1068 20.9232Z"
                fill="#F4A190"
              />
              <path
                d="M121.29 14.6922C121.29 19.4042 125.227 23.3102 130.094 23.3102C134.992 23.3102 138.96 19.4042 138.96 14.6922V1.88923C138.96 1.57923 138.681 1.30023 138.371 1.30023H134.558C134.217 1.30023 133.969 1.57923 133.969 1.88923V14.4442C133.969 16.6142 132.326 18.3502 130.094 18.3502C127.893 18.3502 126.281 16.6142 126.281 14.4442V1.88923C126.281 1.57923 126.033 1.30023 125.692 1.30023H121.879C121.569 1.30023 121.29 1.57923 121.29 1.88923V14.6922Z"
                fill="#F4A190"
              />
              <path
                d="M149.367 22.4112C149.367 22.7212 149.615 23.0002 149.956 23.0002H153.645C153.955 23.0002 154.234 22.7212 154.234 22.4112V14.5062H156.373L160.496 22.7212C160.558 22.8452 160.744 23.0002 160.992 23.0002H165.115C165.642 23.0002 165.859 22.5042 165.642 22.1012L161.395 14.2272C164.092 13.1422 165.983 10.8482 165.983 7.99623C165.983 4.30723 162.945 1.30023 159.225 1.30023H149.956C149.615 1.30023 149.367 1.57923 149.367 1.88923V22.4112ZM154.234 10.5692V5.73323H158.822C160.093 5.73323 161.178 6.78724 161.178 8.08923C161.178 9.39124 160.093 10.5692 158.822 10.5692H154.234Z"
                fill="#F4A190"
              />
              <path
                d="M175.56 22.4112C175.56 22.7212 175.808 23.0002 176.149 23.0002H188.983C189.324 23.0002 189.572 22.7212 189.572 22.4112V19.0322C189.572 18.7222 189.324 18.4432 188.983 18.4432H180.396V14.2582H187.464C187.774 14.2582 188.053 14.0102 188.053 13.6692V10.2902C188.053 9.98023 187.774 9.70123 187.464 9.70123H180.396V5.85723H188.983C189.324 5.85723 189.572 5.57823 189.572 5.26823V1.88923C189.572 1.57923 189.324 1.30023 188.983 1.30023H176.149C175.808 1.30023 175.56 1.57923 175.56 1.88923V22.4112Z"
                fill="#F4A190"
              />
            </svg>
          </Link>
        </li>
      </ul>
      <ul>
        <li>
          <Link
            onClick={() => {
              signoutUser();
            }}
          >
            {signedIn ? "Sign Out" : "Sign In"}
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
