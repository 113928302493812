import React, { useState, useEffect } from "react";
import styled from "styled-components";

import history from "../../../state/history";
import useNickname from "../../../hooks/nickname";
import { validateEmail } from "../../../state/utils";
import ErrorList from "../../shared/components/ErrorList";

const Dropdown = styled.select`
  background: #3e435e;
  border: 1px solid #3e435e;
  border-width: 0 0 1px 0;
  border-radius: 20px;
  color: #fdf6ef;
  outline: 0;
  padding: 0 0.2em;
`;

const Input = styled.input.attrs({ type: "text" })`
  background: #3e435e;
  border: 1px solid #3e435e;
  border-width: 0 0 1px 0;
  border-radius: 17px;
  box-sizing: content-box;
  color: #fdf6ef;
  max-width: 100%;
  outline: 0;
  text-align: center;
`;

const Label = styled.label`
  text-transform: capitalize;
`;

const CreateLifebook = props => {
  const { nickname, onSave: setNickname } = useNickname();

  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: ""
  });

  const [errors, setErrors] = useState(null);

  const relationOptions = [
    "Dad",
    "Mom",
    "Brother",
    "Sister",
    "Aunt",
    "Uncle",
    "Grandma",
    "Grandpa",
    "Cousin",
    "Someone else"
  ];
  const [relation, setRelation] = useState(relationOptions[0]);

  useEffect(() => {
    props.clearLifebookLocal();
  }, [false]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value.trim() });
  };

  const handleRelation = e => setRelation(e.currentTarget.value);

  const handleNickname = e => setNickname(e.currentTarget.value);

  const submitForm = e => {
    // local error validation
    setErrors([]);
    e.preventDefault();
    const errors = [];
    Object.keys(form).forEach(key => {
      if (!form[key]) {
        const error = `${key}: cannot be blank.`;
        errors.push(error);
      }
      if (key === "email" && !validateEmail(form["email"])) {
        errors.push("Email: is not valid.");
      }
    });

    if (errors && errors.length) {
      setErrors(errors);
      return;
    }

    // save lifebook to redux state
    props.createLifebookLocal({ data: form });

    // create lifebook if signed in
    if (props.signedIn) {
      props.createLifebook({
        meta: {
          method: "post",
          route: "lifebooks",
          withInitialQuestions: false,
          afterRedirect: "/onboarding/category"
        },
        data: {
          lifebook: props.lifebook
        }
      });
    } else {
      history.push("/onboarding/category");
    }
  };

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">
            I’d love to ask life questions, and hear stories from, my{" "}
            <Dropdown onChange={handleRelation} value={relation}>
              {relationOptions.map(option => (
                <option key={option}>{option}</option>
              ))}
            </Dropdown>{" "}
            today. Normally, I call my {relation}{" "}
            <Input
              onChange={handleNickname}
              placeholder="enter nickname here"
              value={nickname}
            />
            .
          </h1>
          <p className="large-paragraph">Tell us more about {nickname}</p>
        </div>
        <form className="form-section" onSubmit={submitForm}>
          <ErrorList errors={props.errors} />
          <ErrorList errors={errors} />
          <div className="field-group">
            <Label for="first_name">
              {!!nickname && `${nickname}'s `}First Name
            </Label>
            <input
              type="text"
              name="first_name"
              placeholder="Their First Name"
              onChange={handleInputChange}
            />
          </div>
          <div className="field-group">
            <Label for="last_name">
              {!!nickname && `${nickname}'s `}Last Name
            </Label>
            <input
              type="text"
              name="last_name"
              placeholder="Their Last Name"
              onChange={handleInputChange}
            />
          </div>
          <div className="field-group no-margin">
            <Label for="email">{!!nickname && `${nickname}'s `}Email</Label>
            <input
              type="email"
              name="email"
              placeholder="Their Email Address"
              onChange={handleInputChange}
            />
          </div>
          <div className="field-group no-margin">
            <button className="default-button full-width" type="submit">
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateLifebook;
