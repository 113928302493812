import * as React from "react";
import styled from "styled-components";
import { ZiggeoPlayer } from "react-ziggeo";
import { Link, useParams } from "react-router-dom";

import { getAirtableSetName, getAirtableSetOrder } from "../../helpers";

const VimeoContainer = styled.div`
  padding: 56.25% 0 0 0;
  position: relative;
`;

const VimeoFrame = styled.iframe`
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Landing = props => {
  const { token } = useParams();
  const { lifebook, categories, getCategories, getMyLifebook } = props;

  const [introVideo, setIntroVideo] = React.useState(false);

  const vimeoRef = React.useRef(null);

  // extract token from url and save to localstorage
  React.useEffect(() => {
    localStorage.setItem("token", token);
    getMyLifebook();
    getCategories();
  }, [false]);

  React.useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  const handleMessage = ({ origin, data }) => {
    if (!/^https?:\/\/player.vimeo.com/.test(origin)) {
      return false;
    }
    if (typeof data === "string") {
      try {
        data = JSON.parse(data);
      } catch (error) {
        data = { event: "" };
      }
    }
    if (data.event === "ready") {
      const { current } = vimeoRef;
      if (!current) return;
      try {
        current.contentWindow.postMessage(
          { method: "addEventListener", value: "ended" },
          origin
        );
      } catch (error) {}
    } else if (data.event === "ended") {
      setIntroVideo(false);
    }
  };

  const handlePlayerEnded = () => setIntroVideo(true);

  let unanswered =
    lifebook.questions &&
    lifebook.questions.reduce((acc, curr) => {
      if (
        curr.answer &&
        (curr.answer.video_answer ||
          (curr.answer.audio_answer &&
            curr.answer.audio_answer.status === "success"))
      ) {
      } else {
        acc = [...acc, curr];
      }
      return acc;
    }, []);

  unanswered =
    unanswered &&
    unanswered.reduce((acc, curr) => {
      let airtable_set_name = getAirtableSetName(
        curr.airtable_set_id,
        categories
      );
      let airtable_set_order = getAirtableSetOrder(
        curr.airtable_id,
        curr.airtable_set_id,
        categories
      );
      return [...acc, { ...curr, airtable_set_name, airtable_set_order }];
    }, []);

  unanswered =
    unanswered &&
    unanswered.sort((a, b) => {
      if (a.airtable_set_id === b.airtable_set_id) {
        return a.airtable_set_order > b.airtable_set_order ? 1 : -1;
      }
      return a.airtable_set_name > b.airtable_set_name ? 1 : -1;
    });

  const nextQuestionId = unanswered && unanswered.length && unanswered[0].uuid;

  return (
    <div className="onboarding root-page-container vertical-align-layout welcome-page">
      <div>
        {lifebook && lifebook.questions.length ? (
          <div className="page-content">
            <div className="onboarding__about-opus title">
              <h2 className="heading-two">
                Welcome to your Treasure {lifebook.user.first_name}, the story
                of you and your family!
              </h2>
            </div>
            <div className="onboarding__video-section">
              <div className="top-half">
                <h4 className="heading-four">
                  {lifebook.initiated_by_user.first_name}{" "}
                  {lifebook.initiated_by_user.last_name}
                  <br />
                  has a special message for you!
                </h4>
              </div>
              {lifebook.intro_video && !introVideo ? (
                <ZiggeoPlayer
                  apiKey={process.env.REACT_APP_VIDEO_UPLOAD_TOKEN}
                  height="auto"
                  onEnded={handlePlayerEnded}
                  onError={handlePlayerEnded}
                  skipinitial={false}
                  theme="minimalist"
                  video={lifebook.intro_video.ziggeo_video_token}
                  width="stretch"
                  webrtc_on_mobile
                  webrtc_streaming
                />
              ) : (
                <VimeoContainer>
                  <VimeoFrame
                    ref={vimeoRef}
                    src={`https://player.vimeo.com/video/${process.env.REACT_APP_INTRO_VIDEO_VIMEO_ID}?autoplay=1&title=0&byline=0&portrait=0`}
                    allow="autoplay; fullscreen"
                    allowFullScreen
                  />
                </VimeoContainer>
              )}
            </div>

            <div className="onboarding__about-opus body">
              <p className="large-paragraph">
                Your family wants to know more about you and your life and has
                picked some questions for you. All you have to do is answer.
              </p>

              <div className="recording-options button-selection">
                <Link
                  to={
                    nextQuestionId
                      ? `/interviewee/answer-question/video/${nextQuestionId}`
                      : `/lifebook/${lifebook.uuid}`
                  }
                >
                  <button className="default-button no-margin has-icon video-answer">
                    <svg
                      width="24"
                      height="22"
                      viewBox="0 0 32 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M31.493 2.12989C31.3389 2.0426 31.1643 1.9978 30.9872 2.00008C30.8101 2.00237 30.6368 2.05165 30.485 2.14289L24 6.03389V15.9659L30.485 19.8569C30.6367 19.948 30.8099 19.9973 30.9869 19.9996C31.1639 20.0019 31.3383 19.9572 31.4924 19.8701C31.6464 19.7829 31.7746 19.6565 31.8638 19.5036C31.953 19.3507 32 19.1769 32 18.9999V2.99989C32 2.82299 31.9531 2.64926 31.864 2.49642C31.7749 2.34358 31.6469 2.2171 31.493 2.12989V2.12989Z"
                        fill="#fff"
                      />
                      <path
                        d="M18 0H4C2.93913 0 1.92172 0.421427 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4L0 18C0 19.0609 0.421427 20.0783 1.17157 20.8284C1.92172 21.5786 2.93913 22 4 22H18C19.0609 22 20.0783 21.5786 20.8284 20.8284C21.5786 20.0783 22 19.0609 22 18V4C22 2.93913 21.5786 1.92172 20.8284 1.17157C20.0783 0.421427 19.0609 0 18 0ZM5.5 7C5.20333 7 4.91332 6.91203 4.66664 6.7472C4.41997 6.58238 4.22771 6.34811 4.11418 6.07403C4.00065 5.79994 3.97094 5.49834 4.02882 5.20736C4.0867 4.91639 4.22956 4.64912 4.43934 4.43934C4.64912 4.22956 4.91639 4.0867 5.20736 4.02882C5.49834 3.97094 5.79994 4.00065 6.07403 4.11418C6.34811 4.22771 6.58238 4.41997 6.7472 4.66665C6.91203 4.91332 7 5.20333 7 5.5C7 5.89782 6.84196 6.27936 6.56066 6.56066C6.27936 6.84196 5.89782 7 5.5 7V7Z"
                        fill="#fff"
                      />
                    </svg>
                    Start Recording Answers
                  </button>
                </Link>
                <Link
                  className="default-link has-icon phone-answer large-paragraph"
                  to={
                    nextQuestionId
                      ? `/interviewee/answer-question/phone/${nextQuestionId}`
                      : `/lifebook/${lifebook.uuid}`
                  }
                >
                  <svg
                    height="32"
                    width="24"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      fill="#e95e63"
                      stroke="#e95e63"
                      strokeLinecap="square"
                      strokeWidth="2"
                    >
                      <circle
                        cx="6.5"
                        cy="10.5"
                        r="1.5"
                        stroke="none"
                        strokeLinecap="butt"
                      />
                      <path
                        className="border"
                        d="M9.242,27H19a3,3,0,0,0,3-3V20l9,4V8l-4.964,2.206"
                        fill="none"
                        stroke="#707588"
                      />
                      <path
                        className="border"
                        d="M22,10V8a3,3,0,0,0-3-3H4A3,3,0,0,0,1,8V24a3,3,0,0,0,3,3H5"
                        fill="none"
                        stroke="#707588"
                        strokeLinecap="butt"
                      />
                      <line fill="none" x1="29" x2="3" y1="3" y2="29" />
                    </g>
                  </svg>
                  Answer by phone instead
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="page-content no-questions">
            <h2 className="heading-two">
              Your Life Story doesn't contain any open questions.
            </h2>
            <Link to="/home">
              <button className="default-button no-margin">
                Go to Home Screen
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Landing;
