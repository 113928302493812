import { IntervieweeActionTypes } from "./types";
import { CREATE_ANSWER, UPDATE_LIFEBOOK, CLEAR_MESSAGES } from "./actions";
const {
  GET_LIFEBOOKS_SUCCESS,
  GET_LIFEBOOKS_ERROR,
  GET_LIFEBOOK_SUCCESS,
  GET_LIFEBOOK_ERROR
} = IntervieweeActionTypes;

export const initialState = {
  lifebooks: [],
  lifebook: { questions: [] },
  activeQuestion: {},
  errors: [],
  success: null,
  lifebookUpdatedWithVideo: false
};

export const intervieweeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIFEBOOKS_SUCCESS: {
      return {
        ...state,
        lifebooks: action.payload
      };
    }
    case GET_LIFEBOOK_SUCCESS: {
      return {
        ...state,
        lifebook: action.payload
      };
    }
    case CREATE_ANSWER.SUCCESS: {
      const questions = state.lifebook.questions.map(question => {
        if (action.payload.question === question.id) {
          return Object.assign({}, question, { answer: action.payload });
        } else {
          return question;
        }
      });
      return {
        ...state,
        success: "Answer added to Life Story!",
        errors: [],
        lifebook: {
          ...state.lifebook,
          questions
        }
      };
    }
    case CREATE_ANSWER.ERROR: {
      return {
        ...state,
        errors: action.payload,
        success: null
      };
    }
    case UPDATE_LIFEBOOK.SUCCESS: {
      return {
        ...state,
        success: "Treasure updated successfully!",
        errors: [],
        lifebookUpdatedWithVideo: true
      };
    }
    case UPDATE_LIFEBOOK.ERROR: {
      return {
        ...state,
        errors: action.payload,
        success: null,
        lifebookUpdateWithVideo: false
      };
    }

    case CLEAR_MESSAGES: {
      return {
        ...state,
        errors: [],
        success: null,
        lifebookUpdatedWithVideo: false
      };
    }

    default:
      return state;
  }
};
