import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getCategories } from "../../../state/question/";
import { getAirtableSetName, getAirtableSetOrder } from "../../helpers";

const Author = styled.p`
  margin: 0 0 8px 0;
`;

const AuthorS = styled.span`
  background: #f4a190;
  border-radius: 4px;
  color: #fdf6ef;
  padding: 0.1em 0.5em;
`;

const AnswerQuestionSection = props => {
  const dispatch = useDispatch();
  let unanswered = useSelector(state => state.lifebook.unansweredQuestions);
  const categories = useSelector(state => state.question.categories);

  useEffect(() => {
    dispatch(
      getCategories.request({
        meta: { method: "get", route: "Sets" }
      })
    );
  }, [false]);

  unanswered = unanswered.reduce((acc, curr) => {
    let airtable_set_name = getAirtableSetName(
      curr.airtable_set_id,
      categories
    );
    let airtable_set_order = getAirtableSetOrder(
      curr.airtable_id,
      curr.airtable_set_id,
      categories
    );
    return [...acc, { ...curr, airtable_set_name, airtable_set_order }];
  }, []);

  unanswered = unanswered.sort((a, b) => {
    if (a.airtable_set_id === b.airtable_set_id) {
      return a.airtable_set_order > b.airtable_set_order ? 1 : -1;
    }
    return a.airtable_set_name > b.airtable_set_name ? 1 : -1;
  });

  let sets = [];
  unanswered = unanswered.reduce((acc, curr) => {
    if (sets.indexOf(curr.airtable_set_name) < 0) {
      acc.push(curr);
      sets.push(curr.airtable_set_name);
    }
    return acc;
  }, []);

  return (
    <div className="unanswered-section" id="answer-questions">
      <h2 className="heading-two center">
        {unanswered
          ? `You have ${unanswered.length} unanswered question sets`
          : "No questions left to answer"}
      </h2>

      <div className="questions-container">
        {unanswered &&
          unanswered.map(question => (
            <Link
              key={question.uuid}
              className="question"
              to={`/interviewee/select-media/${question.uuid}`}
            >
              <div className="record-question">
                <svg
                  width="90"
                  height="90"
                  viewBox="0 0 90 90"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M45 90C20.1871 90 0 69.8138 0 45C0 20.1862 20.1871 0 45 0C69.8129 0 90 20.1862 90 45C90 69.8138 69.8129 90 45 90Z"
                    fill="#00A3A9"
                  />
                  <path
                    d="M62.3558 35.3233C62.1877 35.2281 61.9973 35.1792 61.8041 35.1817C61.6109 35.1842 61.4218 35.238 61.2562 35.3375L54.1816 39.5823V50.4172L61.2562 54.6619C61.4217 54.7613 61.6106 54.8151 61.8037 54.8176C61.9968 54.8201 62.1871 54.7714 62.3551 54.6763C62.5232 54.5812 62.663 54.4433 62.7603 54.2765C62.8576 54.1097 62.9089 53.9201 62.9089 53.727V36.2724C62.9089 36.0795 62.8577 35.8899 62.7605 35.7232C62.6634 35.5565 62.5237 35.4185 62.3558 35.3233V35.3233Z"
                    fill="white"
                  />
                  <path
                    d="M47.6364 33H32.3636C31.2063 33 30.0964 33.4597 29.2781 34.2781C28.4597 35.0964 28 36.2063 28 37.3636V52.6364C28 53.7937 28.4597 54.9036 29.2781 55.7219C30.0964 56.5403 31.2063 57 32.3636 57H47.6364C48.7937 57 49.9036 56.5403 50.7219 55.7219C51.5403 54.9036 52 53.7937 52 52.6364V37.3636C52 36.2063 51.5403 35.0964 50.7219 34.2781C49.9036 33.4597 48.7937 33 47.6364 33ZM34 40.6364C33.6764 40.6364 33.36 40.5404 33.0909 40.3606C32.8218 40.1808 32.612 39.9252 32.4882 39.6262C32.3643 39.3272 32.3319 38.9982 32.3951 38.6808C32.4582 38.3633 32.6141 38.0718 32.8429 37.8429C33.0718 37.6141 33.3633 37.4582 33.6808 37.3951C33.9982 37.3319 34.3272 37.3643 34.6262 37.4882C34.9252 37.612 35.1808 37.8218 35.3606 38.0909C35.5404 38.36 35.6364 38.6764 35.6364 39C35.6364 39.434 35.464 39.8502 35.1571 40.1571C34.8502 40.464 34.434 40.6364 34 40.6364V40.6364Z"
                    fill="white"
                  />
                </svg>
              </div>
              <Author>
                <AuthorS>{question.created_by.first_name} asked</AuthorS>
              </Author>
              <p className="large-paragraph">{question.prompt}</p>
              <p className="paragraph">Answer Question</p>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default AnswerQuestionSection;
