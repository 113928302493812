export const IntervieweeActionTypes = {
  GET_LIFEBOOK: "GET_LIFEBOOK",
  GET_LIFEBOOK_SUCCESS: "GET_LIFEBOOK_SUCCESS",
  GET_LIFEBOOK_ERROR: "GET_LIFEBOOK_ERROR",
  GET_LIFEBOOKS: "GET_LIFEBOOKS",
  GET_LIFEBOOKS_SUCCESS: "GET_LIFEBOOKS_SUCCESS",
  GET_LIFEBOOKS_ERROR: "GET_LIFEBOOKS_ERROR",
  UPDATE_LIFEBOOK: "UPDATE_LIFEBOOK",
  UPDATE_LIFEBOOK_SUCCESS: "UPDATE_LIFEBOOK_SUCCESS",
  UPDATE_LIFEBOOK_ERROR: "UPDATE_LIFEBOOK_ERROR",
  CREATE_QUESTION: "CREATE_QUESTION",
  CREATE_QUESTION_SUCCESS: "CREATE_QUESTION_SUCCESs",
  CREATE_QUESTION_ERROR: "CREATE_QUESTION_ERROR",
  CREATE_ANSWER: "CREATE_ANSWER",
  CREATE_ANSWER_SUCCESS: "CREATE_ANSWER_SUCCESS",
  CREATE_ANSWER_ERROR: "CREATE_ANSWER_ERROR"
};
