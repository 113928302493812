const _ = require("lodash");

// ACTIONS

const createActionTypes = base => {
  return {
    REQUEST: `${base}_REQUEST`,
    SUCCESS: `${base}_SUCCESS`,
    ERROR: `${base}_ERROR`
  };
};

const createActions = base => {
  return {
    request: ({ data, meta }) => {
      return { type: `${base}_REQUEST`, meta, payload: data };
    },
    success: res => {
      return { type: `${base}_SUCCESS`, payload: res };
    },
    error: err => {
      return { type: `${base}_ERROR`, payload: err };
    }
  };
};

// ASYNC
export const GET_CATEGORIES = createActionTypes("GET_CATEGORIES");
export const getCategories = createActions("GET_CATEGORIES");

export const getQuestions = createActions("GET_QUESTIONS");
export const GET_QUESTIONS = createActionTypes("GET_QUESTIONS");

const SELECT_CATEGORY = "SELECT_CATEGORY";
export const selectCategory = data => {
  return {
    type: SELECT_CATEGORY,
    payload: data
  };
};

// REDUCER

const initialState = {
  questions: [],
  categories: [],
  selectedCategories: [],
  selectedCategory: null,
  loading: false
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES.SUCCESS: {
      let categories = action.payload.filter(
        cat => cat.fields.Questions && cat.fields.Questions.length
      );
      return {
        ...state,
        categories,
        selectedCategories: _.shuffle(categories).slice(0, 3)
      };
    }

    case GET_QUESTIONS.SUCCESS: {
      return {
        ...state,
        questions: action.payload
      };
    }

    case SELECT_CATEGORY: {
      return {
        ...state,
        selectedCategory: action.payload
      };
    }

    default:
      return state;
  }
};

export default questionReducer;
