import * as React from "react";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";

import ErrorList from "./ErrorList";

export default function PasswordReset() {
  const [errors, setErrors] = React.useState([]);
  const [form, setForm] = React.useState({
    password: "",
    password_confirm: ""
  });
  const [isSaved, setIsSaved] = React.useState(false);

  const history = useHistory();
  const { token } = useParams();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const submitForm = event => {
    event.preventDefault();

    const { password, password_confirm } = form;

    axios
      .post(`${process.env.REACT_APP_API_URL}users/password_reset/`, {
        password,
        password_confirm,
        token,
        email: params.get("email")
      })
      .catch(error => {
        console.log("error", error.response);
        const { data } = error.response;
        setErrors(
          Object.values(data.errors).reduce(
            (accum, errorSet) => [...accum, ...errorSet],
            []
          )
        );
      })
      .then(response => {
        setIsSaved(true);
      });
  };

  const handleInput = event => {
    const { name, value } = event.currentTarget;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSignIn = () => history.push("/signin");

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">Reset password</h1>
          {isSaved && (
            <>
              <p className="large-paragraph">
                You can now use your new password to sign in!
              </p>
              <div className="field-group">
                <button
                  className="default-button"
                  onClick={handleSignIn}
                  type="button"
                >
                  Sign in
                </button>
              </div>
            </>
          )}
        </div>
        {isSaved ? null : (
          <form className="form-section" onSubmit={submitForm}>
            <ErrorList errors={errors} />
            <div className="field-group">
              <label>Password</label>
              <input
                name="password"
                onChange={handleInput}
                placeholder="New password"
                type="password"
                value={form.password}
              />
            </div>
            <div className="field-group">
              <label>Confirm password</label>
              <input
                name="password_confirm"
                onChange={handleInput}
                placeholder="Confirm new password"
                type="password"
                value={form.password_confirm}
              />
            </div>
            <div className="field-group">
              <button className="default-button full-width" type="submit">
                Save
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
