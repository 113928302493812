import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../../state/auth";
import FinishLifebook from "../components/FinishLifebook";

const FinishLifebookPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    user: useSelector(state => state.auth.user),
    errors: useSelector(state => state.auth.errors)
  };

  return <FinishLifebook {...stateToProps}></FinishLifebook>;
};

export default FinishLifebookPage;
