import { all, call, fork, put, takeLatest, select } from "redux-saga/effects";
import {
  signIn,
  signUp,
  getUser,
  updateUser,
  sendInvite,
  acceptInvite,
  SIGN_IN,
  SIGN_UP,
  GET_USER,
  UPDATE_USER,
  SEND_INVITE,
  ACCEPT_INVITE
} from "./";
import lifebooksApi from "../middleware/lifebooksApi";

import history from "../history";
import { processErrors, handleErrors } from "../utils";

function* handleSignin(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload
    );

    localStorage.setItem("token", res.data.token);

    yield put(signIn.success(res.data));

    history.push("/home");

    if (action.meta.afterAction) {
      yield put(action.meta.afterAction);
    }
  } catch (err) {
    yield put(signIn.error(handleErrors(err)));
  }
}

function* handleSignup(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload.user
    );

    localStorage.setItem("token", res.data.token);
    yield put(signUp.success(res.data));

    if (action.meta.afterAction) {
      action.meta.afterAction.payload["user_id"] = res.data.uuid;
      yield put(action.meta.afterAction);
    }

    if (action.meta.afterRedirect) {
      history.push(action.meta.afterRedirect);
    }
  } catch (err) {
    yield put(signUp.error(handleErrors(err)));
  }
}

function* handleGetUser(action) {
  try {
    const res = yield call(lifebooksApi, "get", "me");
    yield put(getUser.success(res.data));
  } catch (err) {
    yield put(getUser.error(handleErrors(err)));
  }
}

function* handleUpdateUser(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload
    );
    if (action.meta.afterAction) {
      yield put(action.meta.afterAction);
    }
    if (action.meta.afterRedirect) {
      history.push(action.meta.afterRedirect);
    }
    yield put(updateUser.success(res));
  } catch (err) {
    yield put(updateUser.error(handleErrors(err)));
  }
}

function* handleSendInvite(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload
    );
    yield put(sendInvite.success(res.data));
  } catch (err) {
    yield put(sendInvite.error(handleErrors(err)));
  }
}

function* handleAcceptInvite(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload
    );
    yield put(acceptInvite.success(res.data));
    history.push(`/lifebook/${res.data.lifebook_uuid}`);
  } catch (err) {
    yield put(acceptInvite.error(handleErrors(err)));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(SIGN_IN.REQUEST, handleSignin),
    takeLatest(SIGN_UP.REQUEST, handleSignup),
    takeLatest(UPDATE_USER.REQUEST, handleUpdateUser),
    takeLatest(GET_USER.REQUEST, handleGetUser),
    takeLatest(SEND_INVITE.REQUEST, handleSendInvite),
    takeLatest(ACCEPT_INVITE.REQUEST, handleAcceptInvite)
  ]);
}
