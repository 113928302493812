import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";

import useNickname from "../../../hooks/nickname";
import { getUrlParameter } from "../../../state/utils";

const Highlight = styled.span`
  background: #3e435e;
  border-radius: 19px;
  color: #fdf6ef;
  padding: 0 0.2em;
`;

const OnboardingSelectCategory = props => {
  const { categories, questions, signedIn, addInitialQuestions } = props;
  const history = useHistory();
  const uuid = getUrlParameter("lifebookUuid");

  // get questions from airtable
  useEffect(() => {
    props.getCategories({
      meta: { method: "get", route: "Sets" },
      data: { queryString: "filterByFormula=Featured" }
    });
    props.getQuestions({
      meta: { method: "get", route: "Questions" },
      data: {}
    });
  }, [false]);

  // add selected questions to redux state
  const selectCategory = category => {
    let tmpQuestions = questions.filter(
      q =>
        q.fields &&
        q.fields["Categories Question is IN"] &&
        q.fields["Categories Question is IN"].includes(category.id)
    );
    tmpQuestions.forEach(q => {
      props.addQuestionToLifebook({
        data: {
          prompt: q.fields.Question,
          airtable_set_id: category.id,
          airtable_id: q.id
        }
      });
    });

    // save questions to lifebook if signed on
    if (signedIn) {
      addInitialQuestions({
        data: {},
        meta: {
          method: "post",
          route: `lifebooks/${uuid}/add_initial_questions`,
          afterRedirect: `/add-video?lifebookUuid=${uuid}`
        }
      });
    } else {
      history.push("/signup?request=true");
    }
  };

  const { nickname } = useNickname();

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">
            Which question are you most excited to hear{" "}
            <Highlight>{nickname}</Highlight> answer?
          </h1>
        </div>
        <div className="onboarding__question-section">
          {categories.map((category, index) => {
            return (
              <>
                <div
                  className="card"
                  onClick={() => {
                    selectCategory(category);
                  }}
                >
                  <div className="add-question">
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M50 100C22.4302 100 0 77.5709 0 50C0 22.4291 22.4302 0 50 0C77.5698 0 100 22.4291 100 50C100 77.5709 77.5698 100 50 100Z"
                        fill="#00A3A9"
                      />
                      <path
                        d="M71.7391 47.826H52.1739V28.2608C52.1739 27.0592 51.2005 26.0869 50 26.0869C48.7994 26.0869 47.826 27.0592 47.826 28.2608V47.826H28.2608C27.0603 47.826 26.0869 48.7984 26.0869 50C26.0869 51.2015 27.0603 52.1739 28.2608 52.1739H47.826V71.7391C47.826 72.9407 48.7994 73.913 50 73.913C51.2005 73.913 52.1739 72.9407 52.1739 71.7391V52.1739H71.7391C72.9396 52.1739 73.913 51.2015 73.913 50C73.913 48.7984 72.9396 47.826 71.7391 47.826Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="first card-shared"></div>
                  <div className="second card-shared"></div>
                  <div className="top-half">
                    <div className="watermark-container">
                      <svg
                        width="20"
                        height="29"
                        viewBox="0 0 20 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.72199 28.2129C6.72199 28.6271 7.09543 29 7.51037 29H12.4896C12.9046 29 13.278 28.6271 13.278 28.2129V6.09H19.2116C19.668 6.09 20 5.71714 20 5.30286V0.787142C20 0.372857 19.668 0 19.2116 0H0.788381C0.33195 0 0 0.372857 0 0.787142V5.30286C0 5.71714 0.33195 6.09 0.788381 6.09H6.72199V28.2129Z"
                          fill="#EDEDED"
                        />
                      </svg>
                    </div>
                    <div className="question-container">
                      <h3 className="question-label" key={index}>
                        {category.fields.Set}
                      </h3>
                    </div>
                  </div>
                  <div className="count-container">
                    <h4 className="count-label">
                      1 /{" "}
                      {(category.fields &&
                        category.fields.Questions &&
                        category.fields.Questions.length) ||
                        0}{" "}
                    </h4>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default OnboardingSelectCategory;
