import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import history from "./state/history";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from "react-ga";
// auth views
import SignInPage from "./views/requester/pages/SignInPage";
import SignUpPage from "./views/requester/pages/SignUpPage";
import SignOutPage from "./views/shared/pages/SignOutPage";
import PasswordReset from "./views/shared/pages/PasswordReset";
import RequestPasswordReset from "./views/shared/pages/RequestPasswordReset";

// interview requester views
import CreateLifebookPage from "./views/requester/pages/CreateLifebookPage";
import OnboardingSelectCategoryPage from "./views/requester/pages/OnboardingSelectCategory";
import AddVideo from "./views/requester/pages/AddVideoPage";
import FinishLifebookPage from "./views/requester/pages/FinishLifebookPage";

// intervieweee views
import LandingPage from "./views/interviewee/pages/LandingPage";
import AnswerQuestionPage from "./views/interviewee/pages/AnswerQuestionPage";
import AnswerQuestionPhonePage from "./views/interviewee/pages/AnswerQuestionPhonePage";
import SetPasswordPage from "./views/interviewee/pages/SetPasswordPage";
import SetCoverPage from "./views/interviewee/pages/SetCoverPage";
import MediaSelectionPage from "./views/interviewee/pages/MediaSelectionPage";

// shared views
import HomePage from "./views/shared/pages/HomePage";
import LifebookPage from "./views/shared/pages/LifebookPage";
import SendInvitePage from "./views/shared/pages/SendInvitePage";
import QuestionSetPage from "./views/shared/pages/QuestionSetPage";

// util views
import NoMatch from "./views/NoMatch";
import Navigation from "./views/shared/components/Navigation";
import Footer from "./views/shared/components/Footer";
import ErrorBoundary from "./views/ErrorBoundary";

import { setErrors } from "./state/auth";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      dispatch({ type: "SET_USER" });
    }
  }, [false]);

  const PrivateRoute = ({ children, ...rest }) => {
    const token = localStorage.getItem("token");

    const redirect = () => {
      dispatch(setErrors({ data: ["Please sign in to continue"] }));
      return <Redirect to="/signin" />;
    };
    return (
      <Route {...rest} render={props => (token ? children : redirect())} />
    );
  };

  history.listen(location => {
    window.scrollTo(0, 0);
  });

  if (process.env.NODE_ENV !== "development") {
    ReactGA.initialize(process.env.REACT_APP_GA_CODE);

    history.listen(location => {
      if (typeof window !== undefined) {
        window.scrollTo(0, 0);
      }
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }

  return (
    <>
      <Router history={history}>
        <ErrorBoundary key={history.location.path}>
          <Navigation></Navigation>
          <Switch>
            {/*** User Routes ***/}
            <Route path="/signin">
              <SignInPage />
            </Route>
            <Route path="/signup">
              <SignUpPage />
            </Route>
            <Route path="/signout">
              <SignOutPage />
            </Route>
            <Route path="/reset-password/:token">
              <PasswordReset />
            </Route>
            <Route path="/reset-password">
              <RequestPasswordReset />
            </Route>
            {/*** Onboarding Routes ***/}
            <Route exact path="/">
              <CreateLifebookPage />
            </Route>
            <Route path="/onboarding/category">
              <OnboardingSelectCategoryPage />
            </Route>
            <PrivateRoute path="/add-video">
              <AddVideo />
            </PrivateRoute>
            <PrivateRoute path="/finish-lifebook/:uuid">
              <FinishLifebookPage />
            </PrivateRoute>
            {/*** Main Routes ***/}
            <PrivateRoute path="/home">
              <HomePage />
            </PrivateRoute>
            <PrivateRoute path="/lifebook/:uuid/questions">
              <QuestionSetPage />
            </PrivateRoute>
            <PrivateRoute exact path="/lifebook/:uuid/:question">
              <LifebookPage />
            </PrivateRoute>
            <PrivateRoute exact path="/lifebook/:uuid">
              <LifebookPage />
            </PrivateRoute>
            <PrivateRoute path="/send-invite/:uuid">
              <SendInvitePage />
            </PrivateRoute>
            {/*** Interviewee Routes ***/}
            <Route path="/interviewee/invite/:token">
              <LandingPage />
            </Route>
            <PrivateRoute path="/interviewee/answer-question/video/:uuid">
              <AnswerQuestionPage />
            </PrivateRoute>
            <PrivateRoute path="/interviewee/answer-question/phone/:uuid">
              <AnswerQuestionPhonePage />
            </PrivateRoute>
            <PrivateRoute path="/interviewee/set-password">
              <SetPasswordPage />
            </PrivateRoute>
            <PrivateRoute path="/interviewee/set-cover">
              <SetCoverPage />
            </PrivateRoute>
            <PrivateRoute path="/interviewee/select-media/:questionUuid">
              <MediaSelectionPage />
            </PrivateRoute>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
          <Footer />
        </ErrorBoundary>
      </Router>
    </>
  );
}

export default App;
