import { all, call, select, put, takeLatest } from "redux-saga/effects";
import { uploadVideoSuccess, uploadVideoError } from "./actions";
import { updateLifebook, createAnswer } from "../interviewee/actions";
import lifebooksApi from "../middleware/lifebooksApi";
import { VideoActionTypes } from "./types";
import { handleErrors } from "../utils";

function* handleUpload(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload
    );

    yield put(uploadVideoSuccess(res));
    const lifebook = yield select(state => state.interviewee.lifebook);

    if (action.meta.is_intro_video) {
      yield put(
        updateLifebook.request({
          meta: { method: "patch", route: `lifebooks/${lifebook.uuid}` },
          data: { intro_video: res.data.id }
        })
      );
    } else if (action.meta.is_interviewee_video) {
      yield put(
        updateLifebook.request({
          meta: { method: "patch", route: `lifebooks/${lifebook.uuid}` },
          data: { interviewee_video: res.data.id }
        })
      );
    } else {
      yield put(
        createAnswer.request({
          meta: {
            method: "post",
            route: "answers",
            afterRedirect: action.meta.afterRedirect
          },
          data: {
            question: action.meta.questionId,
            video_answer: res.data.id
          }
        })
      );
    }
  } catch (err) {
    yield put(uploadVideoError(handleErrors(err)));
  }
}

export default function* videoSaga() {
  yield all([takeLatest(VideoActionTypes.UPLOAD_VIDEO, handleUpload)]);
}
