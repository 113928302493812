import { createActions, createActionTypes } from "../utils";

export const signIn = createActions("SIGN_IN");
export const SIGN_IN = createActionTypes("SIGN_IN");

export const signUp = createActions("SIGN_UP");
export const SIGN_UP = createActionTypes("SIGN_UP");

export const getUser = createActions("GET_USER");
export const GET_USER = createActionTypes("GET_USER");

export const updateUser = createActions("UPDATE_USER");
export const UPDATE_USER = createActionTypes("UPDATE_USER");

export const sendInvite = createActions("SEND_INVITE");
export const SEND_INVITE = createActionTypes("SEND_INVITE");

export const acceptInvite = createActions("ACCEPT_INVITE");
export const ACCEPT_INVITE = createActionTypes("ACCEPT_INVITE");

const SIGN_OUT = "SIGN_OUT";

const SET_ERRORS = "SET_ERRORS";
const SET_USER = "SET_USER";
const CLEAR_MESSAGES = "CLEAR_MESSAGES";

export const setErrors = ({ data }) => {
  return {
    type: SET_ERRORS,
    payload: data
  };
};

export const initialState = {
  user: {},
  signedIn: false,
  loading: false,
  errors: [],
  acceptedInvite: false,
  success: null
};

const clearCache = key => {
  localStorage.removeItem(key);
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case SIGN_IN.SUCCESS: {
      return {
        ...state,
        token: action.payload,
        signedIn: true,
        loading: false,
        errors: []
      };
    }
    case SIGN_IN.ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }
    case SIGN_UP.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case SIGN_UP.SUCCESS: {
      return {
        ...state,
        user: action.payload,
        signedIn: true,
        loading: false,
        errors: []
      };
    }
    case SIGN_UP.ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }
    case SIGN_OUT: {
      clearCache("token");
      return {
        ...state,
        user: {},
        signedIn: false,
        loading: false,
        errors: []
      };
    }
    case GET_USER.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_USER.SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false,
        errors: []
      };
    }
    case GET_USER.ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }
    case UPDATE_USER.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case UPDATE_USER.SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: []
      };
    }
    case UPDATE_USER.ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }
    case SEND_INVITE.SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: [],
        success: "Invite sent"
      };
    }
    case SEND_INVITE.ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
        success: ""
      };
    }
    case ACCEPT_INVITE.SUCCESS: {
      return {
        ...state,
        loading: false,
        acceptedInvite: true
      };
    }
    case ACCEPT_INVITE.ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }
    case "SET_AFTER_UPDATE_ROUTE": {
      return {
        ...state,
        afterUpdateRoute: action.payload
      };
    }
    case SET_USER: {
      return {
        ...state,
        signedIn: true,
        user: action.payload
      };
    }
    case SET_ERRORS: {
      return {
        ...state,
        errors: action.payload
      };
    }
    case CLEAR_MESSAGES: {
      return {
        ...state,
        success: null,
        errors: []
      };
    }

    default:
      return state;
  }
};
