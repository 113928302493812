import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadVideo } from "../../../state/video/actions";
import { getLifebook } from "../../../state/interviewee/actions";
import AddVideo from "../components/AddVideo";

const AddVideoPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    user: useSelector(state => state.auth.user),
    lifebookUpdatedWithVideo: useSelector(
      state => state.interviewee.lifebookUpdatedWithVideo
    )
  };

  const dispatchToProps = {
    uploadVideo: useCallback(params => dispatch(uploadVideo(params)), [
      dispatch
    ]),
    getLifebook: useCallback(params => dispatch(getLifebook(params)), [
      dispatch
    ])
  };

  return <AddVideo {...stateToProps} {...dispatchToProps}></AddVideo>;
};

export default AddVideoPage;
