import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { acceptInvite } from "../../../state/auth";
import { getUrlParameter } from "../../../state/utils";
import ErrorList from "../../shared/components/ErrorList";

const RightText = styled.p`
  text-align: right;
`;

const SignIn = props => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState(null);
  const inviteUuid = getUrlParameter("inviteUuid");
  const submitForm = e => {
    e.preventDefault();

    const afterAction =
      inviteUuid &&
      acceptInvite.request({
        meta: {
          method: "post",
          route: `me/accept_invite`
        },
        data: {
          invitation_uuid: inviteUuid
        }
      });

    props.signIn({
      meta: {
        method: "post",
        route: "api-token-auth",
        afterAction
      },
      data: {
        username: email.toLowerCase(),
        password
      }
    });
  };

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">Sign In</h1>
          <p className="large-paragraph">
            Dont have an account?&nbsp;
            <Link className="default-link" to="/signup">
              Sign up.
            </Link>
          </p>
        </div>
        <form className="form-section">
          <ErrorList errors={props.errors} />
          <div className="field-group">
            <label>Email</label>
            <input
              onChange={e => {
                setEmail(e.target.value);
              }}
              type="email"
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="field-group no-margin">
            <label>Password</label>
            <input
              onChange={e => {
                setPassword(e.target.value);
              }}
              type="password"
              name="password"
              placeholder="Password"
            />
          </div>
          <div className="field-group">
            <button
              className="default-button full-width"
              onClick={e => {
                submitForm(e);
              }}
            >
              Sign In
            </button>
          </div>
          <RightText>
            <Link className="default-link" to="/reset-password">
              Forgot password?
            </Link>
          </RightText>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
