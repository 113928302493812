import React from "react";

const Footer = props => {
  return (
    <div className="footer">
      <ul>
        <li>
          <a href="mailto:help@hitreasure.com">Contact</a>
        </li>
        <li>
          <a
            href="https://docs.google.com/document/d/1Syrw0N0MuUSG480Xi1dr5U_GjkqBgsTofR1Y3O7PGok"
            rel="nofollow noopener"
            target="_blank"
          >
            Legal
          </a>
        </li>
        <li>
          <a
            href="https://forms.gle/q8cdatiLENwoGmHi8"
            rel="nofollow noopener"
            target="_blank"
          >
            Beta Feedback
          </a>
        </li>
      </ul>
      <ul>
        <li>
          <p>© 2020 Treasure.</p>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
