import { IntervieweeActionTypes } from "./types";
import { createActions, createActionTypes } from "../utils";
const {
  GET_LIFEBOOK,
  GET_LIFEBOOK_SUCCESS,
  GET_LIFEBOOK_ERROR,
  GET_LIFEBOOKS,
  GET_LIFEBOOKS_SUCCESS,
  GET_LIFEBOOKS_ERROR
} = IntervieweeActionTypes;

export const createAnswer = createActions("CREATE_ANSWER");
export const CREATE_ANSWER = createActionTypes("CREATE_ANSWER");

export const deleteAnswer = createActions("DELETE_ANSWER");
export const DELETE_ANSWER = createActionTypes("DELETE_ANSWER");

export const updateLifebook = createActions("UPDATE_LIFEBOOK");
export const UPDATE_LIFEBOOK = createActionTypes("UPDATE_LIFEBOOK");

export const GET_MY_LIFEBOOK = "GET_MY_LIFEBOOK";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";

export const getMyLifebook = () => {
  return {
    type: GET_MY_LIFEBOOK,
    meta: {
      method: "get",
      route: "me/lifebook"
    }
  };
};

export const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES
  };
};

export const getLifebooks = () => {
  return {
    type: GET_LIFEBOOKS,
    meta: {
      method: "get",
      route: "lifebooks"
    }
  };
};

export const getLifebooksSuccess = res => {
  return {
    type: GET_LIFEBOOKS_SUCCESS,
    payload: res.data.results
  };
};

export const getLifebooksError = err => {
  return {
    type: GET_LIFEBOOKS_ERROR,
    payload: err
  };
};

export const getLifebook = ({ data }) => {
  const uuid = data && data.uuid;
  return {
    type: GET_LIFEBOOK,
    meta: {
      method: "get",
      route: `lifebooks/${uuid}`
    }
  };
};

export const getLifebookSuccess = res => {
  return {
    type: GET_LIFEBOOK_SUCCESS,
    payload: res.data
  };
};

export const getLifebookError = err => {
  return {
    type: GET_LIFEBOOK_ERROR,
    payload: err
  };
};
