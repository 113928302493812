import React, { useState, useEffect } from "react";
import ReactAudioPlayer from "react-audio-player";
import PlaceholderVideo from "../../../assets/images/blurred-video.mp4";

const AudioAnswer = props => {
  const { audio } = props;

  return (
    <>
      {audio.recording ? (
        <>
          <div className="placeholder-overlay"></div>
          <video
            src={PlaceholderVideo}
            autoPlay
            className="audio-placeholder"
            loop
            type="video/mp4"
          />
          <ReactAudioPlayer src={audio.recording.url} autoPlay controls />
        </>
      ) : (
        <div>No recording</div>
      )}
    </>
  );
};

export default AudioAnswer;
