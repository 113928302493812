import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import SignOut from "../components/SignOut";

const SignOutPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    user: useSelector(state => state.auth.user)
  };

  const dispatchToProps = {
    signOut: useCallback(() => dispatch({ type: "SIGN_OUT" }), [dispatch])
  };

  return <SignOut {...stateToProps} {...dispatchToProps}></SignOut>;
};

export default SignOutPage;
