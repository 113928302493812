import React, { Component } from "react";
import { Link } from "react-router-dom";

class ErrorBoundary extends Component {
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidCatch(error, info) {
    // debug info
    // console.log("error", error);
    // console.log("info", info);
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <h2 className="heading-two">An error has occurred.</h2>
          <a href="/home">Return to Dashboard</a>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
