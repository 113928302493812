import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, withRouter, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { startTwilio } from "../../../state/audio";
import { getAirtableSetName, getAirtableSetOrder } from "../../helpers";

const AnswerQuestionPhone = props => {
  const { lifebook, success, categories } = props;
  const user = lifebook && lifebook.user;
  const { uuid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [telephone, setTelephone] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    props.getMyLifebook();
    dispatch({ type: "CLEAR_MESSAGES" });
    props.getCategories({
      meta: { method: "get", route: "Sets" }
    });
  }, [false]);

  const question =
    (lifebook.questions && lifebook.questions.find(q => q.uuid === uuid)) ||
    lifebook.questions[0];

  let unanswered =
    lifebook.questions &&
    lifebook.questions.filter(question => !question.answer);

  unanswered = unanswered.reduce((acc, curr) => {
    let airtable_set_name = getAirtableSetName(
      curr.airtable_set_id,
      categories
    );
    let airtable_set_order = getAirtableSetOrder(
      curr.airtable_id,
      curr.airtable_set_id,
      categories
    );
    return [...acc, { ...curr, airtable_set_name, airtable_set_order }];
  }, []);

  unanswered = unanswered.sort((a, b) => {
    if (a.airtable_set_id === b.airtable_set_id) {
      return a.airtable_set_order > b.airtable_set_order ? 1 : -1;
    }
    return a.airtable_set_name > b.airtable_set_name ? 1 : -1;
  });

  const nextRoute = () => {
    const index = unanswered.findIndex(q => q.uuid === uuid);
    const afterRedirect =
      index + 1 === unanswered.length
        ? `/lifebook/${lifebook.uuid}`
        : `/interviewee/answer-question/phone/${unanswered[index + 1].uuid}`;
    if (lifebook.user && !lifebook.user.has_password) {
      return `/interviewee/set-password?afterRedirect=${encodeURIComponent(
        afterRedirect
      )}`;
    } else {
      return afterRedirect;
    }
  };

  useEffect(() => {
    if (user && user.phone_number) {
      setTelephone(user.phone_number.substr(1));
    }
  }, [props.lifebook]);

  useEffect(() => {
    const answerId = props.call && props.call.answer_id;
    if (props.callStarted) {
      props.pollTwilio({
        data: {},
        meta: {
          route: `answers/${answerId}`,
          method: "get"
        }
      });
    }
  }, [props.call]);

  useEffect(() => {
    if (props.callStatus === "success") {
      dispatch({ type: "CLEAR_MESSAGES" });
      history.push(nextRoute());
    }
  }, [props.callStatus]);

  const userId = user && user.id;

  const callStatusMap = {
    unknown: "Calling...",
    no_answer: "No Answer",
    success: "Success"
  };

  const deleteAnswer = e => {
    e.preventDefault();
    const answerId = props.call && props.call.answer_id;
    props.deleteAnswer({
      meta: {
        method: "delete",
        route: `answers/${answerId}`
      }
    });
    setTimeout(() => {
      updateUser(e);
    }, 3000);
  };

  const updateUser = e => {
    e.preventDefault();
    setErrors([]);

    if (telephone && telephone.length) {
      props.updateUser({
        data: { phone_number: telephone },
        meta: {
          route: `me/${userId}`,
          method: "patch",
          afterAction: startTwilio.request({
            meta: {
              method: "post",
              route: `lifebooks/${lifebook.uuid}/questions/${question.id}/start_twilio`
            }
          })
        }
      });
    } else {
      setErrors(["Phone number cannot be blank"]);
      return;
    }
    return;
  };

  return (
    <div className="onboarding root-page-container vertical-align-layout answer-question-page">
      <div>
        {lifebook && lifebook.questions.length ? (
          <>
            {props.errors ? (
              <p className="large-paragraph center short-error">
                {props.errors && props.errors.map(error => <div>{error}</div>)}
              </p>
            ) : null}

            <p className="requester paragraph center">
              {lifebook.initiated_by_user.first_name} wants to know
            </p>

            <div className="onboarding__question-section audio">
              <div className="card">
                <div className="first card-shared"></div>
                <div className="second card-shared"></div>

                <div className="question-information-container">
                  <div className="top-half">
                    <div className="watermark-container">
                      <svg
                        width="20"
                        height="29"
                        viewBox="0 0 20 29"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.72199 28.2129C6.72199 28.6271 7.09543 29 7.51037 29H12.4896C12.9046 29 13.278 28.6271 13.278 28.2129V6.09H19.2116C19.668 6.09 20 5.71714 20 5.30286V0.787142C20 0.372857 19.668 0 19.2116 0H0.788381C0.33195 0 0 0.372857 0 0.787142V5.30286C0 5.71714 0.33195 6.09 0.788381 6.09H6.72199V28.2129Z"
                          fill="#EDEDED"
                        />
                      </svg>
                    </div>
                    <div className="question-container">
                      <h2 className="heading-two no-margin">
                        {question.prompt}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="recording-container audio">
                  {!question.answer ? (
                    <>
                      <p className="large-paragraph">
                        Each question is recorded through a new call, make sure
                        to have your phone by you
                      </p>

                      <div className="form-section">
                        <PhoneInput
                          value={telephone}
                          onChange={setTelephone}
                          country="us"
                          disableDropdown={true}
                          required={true}
                        />
                      </div>

                      {/* we dont need this status, since I made it part of the button */}
                      {/* <p className="large-paragraph">
                        Call Status:{" "}
                        {callStatusMap[props.callStatus] || "Call not placed"}
                      </p> */}

                      {props.callStatus === "no_answer" ? (
                        <button
                          className="default-button no-margin width"
                          onClick={e => {
                            deleteAnswer(e);
                          }}
                        >
                          Call again
                        </button>
                      ) : (
                        <button
                          className="default-button no-margin full-width"
                          onClick={e => {
                            updateUser(e);
                          }}
                        >
                          {props.callStatus === "unknown"
                            ? "Calling..."
                            : "Call Me"}
                        </button>
                      )}
                    </>
                  ) : (
                    <h3>You've already answered this question</h3>
                  )}
                </div>
              </div>
            </div>

            <div className="skip-section center">
              <Link className="default-link large-paragraph" to={nextRoute}>
                Skip this question
              </Link>

              <Link
                className="default-link gray large-paragraph"
                to={`/lifebook/${uuid}`}
              >
                Return Later
              </Link>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(AnswerQuestionPhone);
