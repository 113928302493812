import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../../state/auth";
import SignIn from "../components/SignIn";

const SignInPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    user: useSelector(state => state.auth.user),
    errors: useSelector(state => state.auth.errors)
  };

  const dispatchToProps = {
    signIn: useCallback(params => dispatch(signIn.request(params)), [dispatch])
  };

  return <SignIn {...stateToProps} {...dispatchToProps}></SignIn>;
};

export default SignInPage;
