import React from "react";
import { useSelector } from "react-redux";

const Loading = () => {
  let questionLoading = useSelector(state => state.lifebook.loading);
  return (
    <>
      {questionLoading > 0 ? (
        <div className="loading">
          <div className="loader"></div>
        </div>
      ) : null}
    </>
  );
};

export default Loading;
