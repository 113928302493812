import React from "react";
import { Link } from "react-router-dom";

const NoMatch = props => {
  return (
    <div className="root-page-container center vertical-align-layout">
      <div>
        <h1 className="heading-one">This page does not exist. </h1>
        <Link to="/home">
          <button className="default-button no-margin">
            Return to Dashboard
          </button>
        </Link>
      </div>
    </div>
  );
};

export default NoMatch;
