import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getLifebookSuccess,
  getLifebookError,
  getLifebooksSuccess,
  getLifebooksError,
  // better actions and types
  createAnswer,
  CREATE_ANSWER,
  deleteAnswer,
  DELETE_ANSWER,
  updateLifebook,
  UPDATE_LIFEBOOK,
  GET_MY_LIFEBOOK
} from "./actions";
import lifebooksApi from "../middleware/lifebooksApi";
import { IntervieweeActionTypes } from "./types";
import { handleErrors } from "../utils";
import history from "../history";

function* handleGetLifebook(action) {
  try {
    const res = yield call(lifebooksApi, action.meta.method, action.meta.route);
    yield put(getLifebookSuccess(res));
  } catch (err) {
    yield put(getLifebookError(handleErrors(err)));
  }
}

function* handleGetLifebooks(action) {
  try {
    const res = yield call(lifebooksApi, action.meta.method, action.meta.route);
    yield put(getLifebooksSuccess(res));
  } catch (err) {
    yield put(getLifebooksError(handleErrors(err)));
  }
}

function* handleUpdateLifebook(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload
    );

    yield put(updateLifebook.success(res));
  } catch (err) {
    yield put(updateLifebook.error(handleErrors(err)));
  }
}

function* handleCreateAnswer(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload
    );
    if (action.meta.afterRedirect) {
      history.push(action.meta.afterRedirect);
    }
    yield put(createAnswer.success(res.data));
  } catch (err) {
    yield put(createAnswer.error(handleErrors(err)));
  }
}

function* handleDeleteAnswer(action) {
  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      action.payload
    );
    yield put(deleteAnswer.success(res.data));
  } catch (err) {
    yield put(deleteAnswer.error(handleErrors(err)));
  }
}

export default function* intervieweeSaga() {
  yield all([
    takeLatest(IntervieweeActionTypes.GET_LIFEBOOK, handleGetLifebook),
    takeLatest(GET_MY_LIFEBOOK, handleGetLifebook),
    takeLatest(IntervieweeActionTypes.GET_LIFEBOOKS, handleGetLifebooks),
    takeLatest(UPDATE_LIFEBOOK.REQUEST, handleUpdateLifebook),
    takeLatest(CREATE_ANSWER.REQUEST, handleCreateAnswer),
    takeLatest(DELETE_ANSWER.REQUEST, handleDeleteAnswer)
  ]);
}
