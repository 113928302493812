import {
  SELECT_QUESTION,
  CLEAR_MESSAGES,
  GET_LIFEBOOK,
  GET_LIFEBOOKS,
  CREATE_QUESTION,
  SELECT_LIFEBOOK,
  PLAY_NEXT_MEDIA,
  UPDATE_ANSWERED_QUESTIONS
} from "./actions";

export const initialState = {
  lifebooks: [],
  lifebook: {},
  questions: [],
  answeredQuestions: [],
  answeredQuestionsSorted: [],
  unansweredQuestions: [],
  selectedQuestion: null,
  errors: [],
  success: "",
  loading: false
};

export const lifebookReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_QUESTION: {
      return {
        ...state,
        selectedQuestion: action.payload
      };
    }
    case GET_LIFEBOOKS.SUCCESS: {
      return {
        ...state,
        lifebooks: action.payload
      };
    }
    case GET_LIFEBOOK.SUCCESS: {
      const lifebook = action.payload;
      const questions = lifebook.questions;
      const { answeredQuestions, unansweredQuestions } = questions.reduce(
        (acc, curr) => {
          if (
            curr.answer &&
            (curr.answer.video_answer ||
              (curr.answer.audio_answer &&
                curr.answer.audio_answer.status === "success"))
          ) {
            acc.answeredQuestions = [...acc.answeredQuestions, curr];
          } else {
            acc.unansweredQuestions = [...acc.unansweredQuestions, curr];
          }
          return acc;
        },
        { answeredQuestions: [], unansweredQuestions: [] }
      );

      return {
        ...state,
        lifebook,
        questions,
        answeredQuestions,
        unansweredQuestions
      };
    }
    case SELECT_LIFEBOOK: {
      return {
        ...state,
        lifebook: action.payload
      };
    }
    case PLAY_NEXT_MEDIA: {
      const id = action.payload;
      const index = state.answeredQuestionsSorted.findIndex(q => q.id === id);
      let selectedQuestion;
      if (index === state.answeredQuestionsSorted.length - 1) {
        selectedQuestion = null;
      } else {
        selectedQuestion = state.answeredQuestionsSorted[index + 1];
      }
      return {
        ...state,
        selectedQuestion
      };
    }
    case CREATE_QUESTION.REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case CREATE_QUESTION.SUCCESS: {
      return {
        ...state,
        lifebook: {
          ...state.lifebook,
          questions: [...state.lifebook.questions, action.payload]
        },
        questions: [...state.questions, action.payload],
        unansweredQuestions: [...state.unansweredQuestions, action.payload],
        success: "Question added to Life Story!",
        errors: [],
        loading: false
      };
    }
    case CREATE_QUESTION.ERROR: {
      return {
        ...state,
        errors: action.payload,
        success: "",
        loading: false
      };
    }
    case CLEAR_MESSAGES: {
      return {
        ...state,
        errors: [],
        success: null,
        lifebookUpdatedWithVideo: false
      };
    }
    case UPDATE_ANSWERED_QUESTIONS: {
      return {
        ...state,
        answeredQuestionsSorted: action.payload
      };
    }
    default:
      return state;
  }
};
