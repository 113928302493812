import { all, call, put, takeLatest, select } from "redux-saga/effects";
import {
  createLifebook,
  CREATE_LIFEBOOK,
  addInitialQuestions,
  ADD_INITIAL_QUESTIONS,
  sendIntervieweeInvitation,
  SEND_INTERVIEWEE_INVITATION
} from "./actions";
import lifebooksApi from "../middleware/lifebooksApi";
import history from "../history";
import { handleErrors } from "../utils";

function* handleCreateLifebook(action) {
  try {
    const lifebook = yield select(state => state.requester.lifebook);

    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      lifebook
    );

    if (action.meta.withInitialQuestions) {
      yield put(
        addInitialQuestions.request({
          data: {},
          meta: {
            method: "post",
            route: `lifebooks/${res.data.uuid}/add_initial_questions`
          }
        })
      );
    }

    if (action.meta.afterRedirect) {
      history.push(
        `${action.meta.afterRedirect}?lifebookUuid=${res.data.uuid}`
      );
    }

    yield put(createLifebook.success(res));
  } catch (err) {
    yield put(createLifebook.error(handleErrors(err)));
  }
}

function* handleAddInitialQuestions(action) {
  const questions = yield select(state => state.requester.questions);

  try {
    const res = yield call(
      lifebooksApi,
      action.meta.method,
      action.meta.route,
      questions
    );

    yield put(addInitialQuestions.success(res));

    if (action.meta.afterRedirect) {
      history.push(`${action.meta.afterRedirect}`);
    }
  } catch (err) {
    yield put(addInitialQuestions.error(handleErrors(err)));
  }
}

function* handleSendIntervieweeInvitation(action) {
  try {
    const res = yield call(lifebooksApi, action.meta.method, action.meta.route);

    yield put(sendIntervieweeInvitation.success(res));

    if (action.meta.afterRedirect) {
      history.push(`${action.meta.afterRedirect}`);
    }
  } catch (err) {
    sendIntervieweeInvitation.error(handleErrors(err));
  }
}

export default function* requesterSaga() {
  yield all([
    takeLatest(CREATE_LIFEBOOK.REQUEST, handleCreateLifebook),
    takeLatest(ADD_INITIAL_QUESTIONS.REQUEST, handleAddInitialQuestions),
    takeLatest(
      SEND_INTERVIEWEE_INVITATION.REQUEST,
      handleSendIntervieweeInvitation
    )
  ]);
}
