import * as React from "react";
import Select from "react-select";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import useNickname from "../../../hooks/nickname";
import { acceptInvite } from "../../../state/auth";
import { getUrlParameter } from "../../../state/utils";
import ErrorList from "../../shared/components/ErrorList";
import { createLifebook } from "../../../state/requester/actions";

const Highlight = styled.span`
  background: #3e435e;
  border-radius: 19px;
  color: #fdf6ef;
  padding: 0 0.2em;
`;

const SignUp = props => {
  // these styles are repeated on the CreateLifebook page
  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: "19px",
      borderWidth: state.isFocused ? "2px" : "1px",
      boxSizing: "border-box",
      borderColor: state.isFocused ? "#3e435e" : "#f8e2cd",
      boxShadow: "0px 5px 13px rgba(244, 161, 144, 0.12)",
      fontSize: "1.19rem",
      fontFamily: "metropolismedium",
      color: "#2b2d34",
      paddingLeft: state.isFocused ? "11px" : "12px",
      margin: "0",
      "&:hover": {
        paddingLeft: "11px",
        borderWidth: "2px",
        borderColor: "#3e435e"
      }
    }),
    valueContainer: base => ({
      ...base,
      padding: "0",
      boxSizing: "border-box"
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "#3e435e",
      padding: state.isFocused ? "7px 11px" : "8px 12px"
    }),
    indicatorSeparator: base => ({
      ...base,
      display: "none"
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#f8e2cd" : "#fff",
      color: "#2b2d34"
    }),
    menu: base => ({
      ...base,
      borderRadius: "19px",
      hyphens: "auto",
      fontSize: "1.19rem",
      marginTop: 0,
      textAlign: "left",
      wordWrap: "break-word"
    }),
    menuList: base => ({
      ...base,
      paddingTop: "14px",
      paddingBottom: "14px"
    })
  };

  const [form, setForm] = React.useState({
    dob: "",
    email: "",
    first_name: "",
    gender: "male",
    last_name: "",
    password: ""
  });
  const inviteUuid = getUrlParameter("inviteUuid");
  const request = getUrlParameter("request");
  const dispatch = useDispatch();

  const [gender, setGender] = React.useState(null);
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" }
  ];

  const handleInputChange = e => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSelectChange = obj => {
    setGender(obj);
    setForm({ ...form, gender: obj.value });
  };

  React.useEffect(() => {
    dispatch({ type: "CLEAR_MESSAGES" });
  }, []);

  const submitForm = e => {
    e.preventDefault();
    let afterAction;
    let afterRedirect;

    if (inviteUuid) {
      afterAction = acceptInvite.request({
        meta: {
          method: "post",
          route: `me/accept_invite`,
          afterRedirect: "/home"
        },
        data: {
          invitation_uuid: inviteUuid
        }
      });
    }

    if (request) {
      afterAction = createLifebook.request({
        meta: {
          method: "post",
          route: "lifebooks",
          withInitialQuestions: true,
          afterRedirect: "/add-video"
        },
        data: {
          lifebook: props.lifebook
        }
      });
    }

    if (!inviteUuid && !request) {
      afterRedirect = "/home";
    }

    form["email"] = form["email"] && form["email"].toLowerCase();

    props.signUp({
      meta: {
        method: "post",
        route: "users",
        afterAction,
        afterRedirect
      },
      data: {
        user: form
      }
    });
  };

  const { nickname } = useNickname();

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">
            OK, it’s time to create a profile for{" "}
            <Highlight>yourself</Highlight> so you can see{" "}
            <Highlight>{nickname}'s</Highlight> initial responses, and
            permanently access their stories for years to come.
          </h1>
        </div>
        <form className="form-section" onSubmit={submitForm}>
          <ErrorList errors={props.lifebookErrors} header="Errors" />
          <ErrorList errors={props.authErrors} header="User Errors" />
          <div className="field-group">
            <label for="first_name">First Name</label>
            <input
              name="first_name"
              onChange={handleInputChange}
              placeholder="Your First Name"
              type="text"
              value={form.first_name}
            />
          </div>
          <div className="field-group">
            <label for="last_name">Last Name</label>
            <input
              name="last_name"
              onChange={handleInputChange}
              placeholder="Your Last Name"
              type="text"
              value={form.last_name}
            />
          </div>
          <div className="field-sets">
            <div className="first field-group">
              <label for="gender">Gender</label>
              <Select
                name="gender"
                onChange={handleSelectChange}
                options={genderOptions}
                styles={customSelectStyles}
                value={gender}
              />
            </div>
            <div className="second field-group">
              <label for="dob">Date of Birth</label>
              <input
                name="dob"
                onChange={handleInputChange}
                type="date"
                value={form.dob}
              />
            </div>
          </div>
          <div className="field-group">
            <label for="email">Email</label>
            <input
              name="email"
              onChange={handleInputChange}
              placeholder="Your Email"
              type="email"
              value={form.email}
            />
          </div>
          <div className="field-group no-margin">
            <label>Password</label>
            <input
              name="password"
              onChange={handleInputChange}
              placeholder="Password"
              type="password"
              value={form.password}
            />
          </div>
          <div className="field-group no-margin">
            <button className="default-button full-width" type="submit">
              Create Account
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
