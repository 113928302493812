import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyLifebook } from "../../../state/interviewee/actions";
import { uploadVideo } from "../../../state/video/actions";
import AnswerQuestion from "../components/AnswerQuestion";
import { getCategories } from "../../../state/question/";

const AnswerQuestionPage = props => {
  const dispatch = useDispatch();

  const stateToProps = {
    lifebook: useSelector(state => state.interviewee.lifebook),
    categories: useSelector(state => state.question.categories),
    success: useSelector(state => state.interviewee.success),
    errors: [
      ...useSelector(state => state.interviewee.errors),
      useSelector(state => state.video.errors)
    ]
  };

  const dispatchToProps = {
    getMyLifebook: useCallback(() => dispatch(getMyLifebook()), [dispatch]),
    uploadVideo: useCallback(params =>
      dispatch(uploadVideo(params), [dispatch])
    ),
    getCategories: useCallback(
      params => dispatch(getCategories.request(params)),
      [dispatch]
    )
  };

  return (
    <AnswerQuestion {...stateToProps} {...dispatchToProps}></AnswerQuestion>
  );
};

export default AnswerQuestionPage;
