import { createActions, createActionTypes } from "../utils";

export const getLifebook = createActions("GET_LIFEBOOK");
export const GET_LIFEBOOK = createActionTypes("GET_LIFEBOOK");

export const getLifebooks = createActions("GET_LIFEBOOKS");
export const GET_LIFEBOOKS = createActionTypes("GET_LIFEBOOKS");

export const createQuestion = createActions("CREATE_QUESTION");
export const CREATE_QUESTION = createActionTypes("CREATE_QUESTION");

export const SELECT_QUESTION = "SELECT_QUESTION";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const SELECT_LIFEBOOK = "SELECT_LIFEBOOK";
export const PLAY_NEXT_MEDIA = "PLAY_NEXT_MEDIA";
export const UPDATE_ANSWERED_QUESTIONS = "UPDATE_ANSWERED_QUESTIONS";

export const selectQuestion = param => {
  return {
    type: SELECT_QUESTION,
    payload: param
  };
};

export const selectLifebook = lifebook => {
  return {
    type: SELECT_LIFEBOOK,
    payload: lifebook
  };
};

export const playNextMedia = id => {
  return {
    type: PLAY_NEXT_MEDIA,
    payload: id
  };
};

export const updateAnsweredQuestions = questions => {
  return {
    type: UPDATE_ANSWERED_QUESTIONS,
    payload: questions
  };
};
