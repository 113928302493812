import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { updateAnsweredQuestions } from "../../../state/lifebook/actions";
import { getAirtableSetName, getAirtableSetOrder } from "../../helpers";

const QuestionList = props => {
  const dispatch = useDispatch();
  const { lifebook } = props;
  let answeredQuestions = useSelector(
    state => state.lifebook.answeredQuestions
  );
  const categories = useSelector(state => state.question.categories);
  const selectedQuestion = useSelector(
    state => state.lifebook.selectedQuestion
  );

  answeredQuestions = answeredQuestions.reduce((acc, curr) => {
    let airtable_set_name = getAirtableSetName(
      curr.airtable_set_id,
      categories
    );
    let airtable_set_order = getAirtableSetOrder(
      curr.airtable_id,
      curr.airtable_set_id,
      categories
    );
    return [...acc, { ...curr, airtable_set_name, airtable_set_order }];
  }, []);

  answeredQuestions = answeredQuestions.sort((a, b) => {
    if (a.airtable_set_id === b.airtable_set_id) {
      return a.airtable_set_order > b.airtable_set_order ? 1 : -1;
    }
    return a.airtable_set_name > b.airtable_set_name ? 1 : -1;
  });

  dispatch(updateAnsweredQuestions(answeredQuestions));

  const history = useHistory();

  const handleQuestionSelect = question => () => {
    history.push(`/lifebook/${lifebook.uuid}/${question.uuid}`);
  };

  return (
    <>
      {answeredQuestions &&
        answeredQuestions.map(question => {
          return (
            <div
              className={`playlist-item ${
                selectedQuestion &&
                question.id === selectedQuestion.id &&
                "selected"
              }`}
              key={question.uuid}
              onClick={handleQuestionSelect(question)}
            >
              <div className="icon-container">
                {selectedQuestion && question.id === selectedQuestion.id ? (
                  <div className="brand-icon">
                    <svg
                      width="15"
                      height="21"
                      viewBox="0 0 15 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.74922 20.0311C4.74922 20.3253 5.01306 20.59 5.30623 20.59H8.82417C9.11733 20.59 9.38117 20.3253 9.38117 20.0311V4.3239H13.5734C13.8959 4.3239 14.1304 4.05917 14.1304 3.76503V0.558871C14.1304 0.264729 13.8959 0 13.5734 0H0.557007C0.234529 0 0 0.264729 0 0.558871V3.76503C0 4.05917 0.234529 4.3239 0.557007 4.3239H4.74922V20.0311Z"
                        fill="#7B809D"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="type-icon">
                    {question.answer.video_answer ? (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0)">
                          <path d="M14 1.33301H2C1.46957 1.33301 0.960859 1.54372 0.585786 1.91879C0.210714 2.29387 0 2.80257 0 3.33301L0 12.6663C0 13.1968 0.210714 13.7055 0.585786 14.0806C0.960859 14.4556 1.46957 14.6663 2 14.6663H14C14.5304 14.6663 15.0391 14.4556 15.4142 14.0806C15.7893 13.7055 16 13.1968 16 12.6663V3.33301C16 2.80257 15.7893 2.29387 15.4142 1.91879C15.0391 1.54372 14.5304 1.33301 14 1.33301ZM5.33333 11.9997V3.99967L12 7.99967L5.33333 11.9997Z" />
                        </g>
                        <defs>
                          <clipPath id="clip0">
                            <rect width="16" height="16" fill="#fff" />
                          </clipPath>
                        </defs>
                      </svg>
                    ) : (
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7.5 15C7.16848 15 6.85054 14.8683 6.61612 14.6339C6.3817 14.3995 6.25 14.0815 6.25 13.75V1.25C6.25 0.918479 6.3817 0.600537 6.61612 0.366117C6.85054 0.131696 7.16848 0 7.5 0C7.83152 0 8.14946 0.131696 8.38388 0.366117C8.6183 0.600537 8.75 0.918479 8.75 1.25V13.75C8.75 14.0815 8.6183 14.3995 8.38388 14.6339C8.14946 14.8683 7.83152 15 7.5 15Z" />
                        <path d="M2.5 12.5C2.16848 12.5 1.85054 12.3683 1.61612 12.1339C1.3817 11.8995 1.25 11.5815 1.25 11.25V3.75C1.25 3.41848 1.3817 3.10054 1.61612 2.86612C1.85054 2.6317 2.16848 2.5 2.5 2.5C2.83152 2.5 3.14946 2.6317 3.38388 2.86612C3.6183 3.10054 3.75 3.41848 3.75 3.75V11.25C3.75 11.5815 3.6183 11.8995 3.38388 12.1339C3.14946 12.3683 2.83152 12.5 2.5 12.5Z" />
                        <path d="M12.5 10C12.1685 10 11.8505 9.8683 11.6161 9.63388C11.3817 9.39946 11.25 9.08152 11.25 8.75V6.25C11.25 5.91848 11.3817 5.60054 11.6161 5.36612C11.8505 5.1317 12.1685 5 12.5 5C12.8315 5 13.1495 5.1317 13.3839 5.36612C13.6183 5.60054 13.75 5.91848 13.75 6.25V8.75C13.75 9.08152 13.6183 9.39946 13.3839 9.63388C13.1495 9.8683 12.8315 10 12.5 10Z" />
                      </svg>
                    )}
                  </div>
                )}
                <div className="play-icon">
                  <svg
                    width="39"
                    height="39"
                    viewBox="0 0 39 39"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M26.8027 19.595L16.7453 14.0634C16.6681 14.0205 16.581 13.9987 16.4927 14.0001C16.4045 14.0015 16.3181 14.0261 16.2424 14.0715C16.1681 14.1165 16.1067 14.18 16.0642 14.2558C16.0217 14.3316 15.9996 14.4171 16 14.5039V25.5671C16.0001 25.6545 16.023 25.7403 16.0664 25.8162C16.1098 25.892 16.1722 25.9552 16.2474 25.9996C16.3246 26.0459 16.4129 26.0702 16.5029 26.07C16.5876 26.07 16.671 26.0485 16.7453 26.0076L26.8027 20.476C26.8815 20.4326 26.9473 20.3689 26.9931 20.2914C27.0389 20.2139 27.063 20.1255 27.063 20.0355C27.063 19.9455 27.0389 19.8572 26.9931 19.7797C26.9473 19.7022 26.8815 19.6384 26.8027 19.595Z" />
                    <circle cx="19.5" cy="19.5" r="18.5" strokeWidth="2" />
                  </svg>
                </div>
              </div>
              <div className="playlist-name">
                <p className="large-paragraph">{question.prompt}</p>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default QuestionList;
