import {
  all,
  call,
  select,
  race,
  delay,
  take,
  put,
  takeLatest
} from "redux-saga/effects";
import { startTwilio, pollTwilio, START_TWILIO, POLL_TWILIO } from "./";
import lifebooksApi from "../middleware/lifebooksApi";
import { handleErrors } from "../utils";

function* handleStartTwilio(action) {
  try {
    const res = yield call(lifebooksApi, action.meta.method, action.meta.route);
    yield put(startTwilio.success(res.data));
  } catch (err) {
    yield put(startTwilio.error(handleErrors(err)));
  }
}

function* handlePollTwilio(action) {
  let complete = false;
  while (!complete) {
    try {
      const res = yield call(
        lifebooksApi,
        action.meta.method,
        action.meta.route
      );
      if (res.data && res.data.audio_answer.status !== "unknown") {
        complete = true;
      }
      yield put(pollTwilio.success(res.data));
    } catch (err) {
      yield put(pollTwilio.error(handleErrors(err)));
    }
    yield delay(2000);
  }
}

export default function* videoSaga() {
  yield all([
    takeLatest(START_TWILIO.REQUEST, handleStartTwilio),
    takeLatest(POLL_TWILIO.REQUEST, handlePollTwilio)
  ]);
}
