import { all, call, put, fork, delay, takeLatest } from "redux-saga/effects";
import airtableApi from "../middleware/airtableApi";
import {
  getCategories,
  getQuestions,
  GET_CATEGORIES,
  GET_QUESTIONS
} from "./index";
import { createQuestion } from "../lifebook/actions";
import { handleErrors } from "../utils";

const handlePaginatedFetch = async (action, offset, airtableLimitReached) => {
  let queryString =
    action.payload && action.payload.queryString
      ? "&" + action.payload.queryString
      : "";
  let path = action.meta.route + "?pageSize=100" + queryString;
  if (offset) {
    path += "&offset=" + offset;
  }
  let res = await airtableApi(action.meta.method, path);
  let records = res.data.records;

  offset = res.data.offset;
  airtableLimitReached = offset ? false : true;

  if (airtableLimitReached) {
    return res.data.records;
  } else {
    let temp = await handlePaginatedFetch(action, offset, airtableLimitReached);
    return records.concat(temp);
  }
};

function* handleGetCategories(action) {
  try {
    const records = yield call(handlePaginatedFetch, action);
    yield put(getCategories.success(records));
  } catch (err) {
    yield put(getCategories.error(handleErrors(err)));
  }
}

function* handleGetQuestions(action) {
  try {
    let records = yield call(handlePaginatedFetch, action);
    if (action.meta.createQuestionSet) {
      for (let record of records) {
        const question = {
          meta: action.meta.afterAction.meta,
          data: {
            prompt: record.fields.Question,
            airtable_id: record.id,
            ...action.meta.afterAction.data
          }
        };
        yield put(createQuestion.request(question));
      }
    }
    yield put(getQuestions.success(records));
  } catch (err) {
    yield put(getQuestions.error(handleErrors(err)));
  }
}

export default function* questionSaga() {
  yield all([
    takeLatest(GET_CATEGORIES.REQUEST, handleGetCategories),
    takeLatest(GET_QUESTIONS.REQUEST, handleGetQuestions)
  ]);
}
