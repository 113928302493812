import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import useNickname from "../../../hooks/nickname";
import { sendIntervieweeInvitation } from "../../../state/requester/actions";

const Highlight = styled.span`
  background: #3e435e;
  border-radius: ${props => (props.small ? 7 : 19)}px;
  color: #fdf6ef;
  padding: 0 0.2em;
`;

const FinishLifebook = props => {
  const { uuid } = useParams();
  const dispatch = useDispatch();

  const sendIntervieweeInvite = () => {
    dispatch(
      sendIntervieweeInvitation.request({
        meta: {
          method: "post",
          route: `lifebooks/${uuid}/send_interviewee_invitation`
        }
      })
    );
  };

  const { nickname } = useNickname();

  return (
    <div className="onboarding root-page-container vertical-align-layout">
      <div>
        <div className="onboarding__heading-section">
          <h1 className="heading-one">
            Your questions are being sent to <Highlight>{nickname}</Highlight>{" "}
            as we speak, and before long you’ll have stories and memories that
            will last a lifetime.
          </h1>
          <p className="large-paragraph">
            Now you'll have a chance to invite your other family and friends so
            they can ask and view <Highlight>{nickname}'s</Highlight> story too!
          </p>
          <Link to={`/send-invite/${uuid}`}>
            <button
              className="default-button"
              onClick={() => {
                sendIntervieweeInvite();
              }}
            >
              Confirm and Invite
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FinishLifebook;
